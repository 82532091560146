import { formatDate } from '@utils';

export const handleDriverseCampaigns = (data = [], members = []) => {
  if (!data || !data.length) {
    return [];
  }

  const membersMap = members?.reduce((acc, cur) => {
    acc[cur.id] = {
      ...cur,
    };
    return acc;
  }, {});

  return data.map((item) => ({
    ...item,
    partnerName: membersMap[item.memberId]?.name || '-',
    title: item?.title?.en || '-',
    duration: `${
      item?.startingAt ? formatDate(item?.startingAt, 'DD/MM/YYYY ') : ''
    } - ${item?.endingAt ? formatDate(item?.endingAt, 'DD/MM/YYYY ') : ''}`,
    status: item?.isActive,
    remainingCoupons: item?.remainingCoupons || '-',
  }));
};

export const handleDriverseMembers = (data = []) => {
  if (!data || !data.length) {
    return [];
  }

  return data
    ?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    ?.map((item) => ({
      text: item?.name,
      value: item?.id,
    }));
};
