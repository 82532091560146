export const handleConfigurationData = (array = []) => {
  if (!array) {
    return [];
  }
  const data = array?.map((item) => ({
    text: item?.name,
    value: JSON.stringify({
      slug: item?.slug,
      name: item?.name,
      json: item?.value,
    }),
  }));

  data.push({
    text: 'New configuration',
    value: JSON.stringify({
      name: 'New configuration',
    }),
  });

  return data;
};
