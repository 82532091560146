import getCleanedUrlParams from './getCleanedUrlParams';

const getUrl = (params, url) => {
  const urlParams = getCleanedUrlParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `${url}?${searchParams}`;
};

export default getUrl;
