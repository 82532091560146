import dynamic from 'next/dynamic';

export * from './FormComponents';

const Button = dynamic(() => import('./Button/Button'));
const Title = dynamic(() => import('./Title/Title'));
const IconButton = dynamic(() => import('./IconButton/IconButton'));
const DataGrid = dynamic(() => import('./DataGrid/DataGrid'));
const GridCellExpand = dynamic(() => import('./GridCellExpand/GridCellExpand'));
const Pagination = dynamic(() => import('./Pagination/Pagination'));
const Image = dynamic(() => import('./Image/Image'));
const InputField = dynamic(() => import('./InputField/InputField'));
const SubTitle = dynamic(() => import('./SubTitle/SubTitle'));
const GridCellLink = dynamic(() => import('./GridCellLink/GridCellLink'));
const Link = dynamic(() => import('./Link/Link'));
const JsonList = dynamic(() => import('./JsonList/JsonList'));
const GridCellBoolean = dynamic(() =>
  import('./GridCellBoolean/GridCellBoolean')
);
const Paragraph = dynamic(() => import('./Paragraph/Paragraph'));
const Expanded = dynamic(() => import('./Expanded/Expanded'));
const ExpandedItem = dynamic(() => import('./ExpandedItem/ExpandedItem'));
const GridCellActiveStatus = dynamic(() =>
  import('./GridCellActiveStatus/GridCellActiveStatus')
);
const LocationSearch = dynamic(() => import('./LocationSearch/LocationSearch'));
const SearchAddress = dynamic(() => import('./SearchAddress/SearchAddress'));
const EmptyContent = dynamic(() => import('./EmptyContent/EmptyContent'));
const FileDropZone = dynamic(() => import('./FileDropZone/FileDropZone'));
const ToggleButton = dynamic(() => import('./ToggleButtons/ToggleButtons'));
const CSVGrid = dynamic(() => import('./CSVGrid/CSVGrid'));
const ExpandItem = dynamic(() => import('./ExpandItem/ExpandItem'));
const FilterSearchBar = dynamic(() =>
  import('./FilterSearchBar/FilterSearchBar')
);
const Logo = dynamic(() => import('./Logo/Logo'));
const GlobalSearch = dynamic(() => import('./GlobalSearch/GlobalSearch'));
const Breadcrumps = dynamic(() => import('./Breadcrumps/Breadcrumps'));
const RouteGuard = dynamic(() => import('./RouteGuard/RouteGuard'));
const LogoBreadcrump = dynamic(() => import('./LogoBreadcrump/LogoBreadcrump'));
const Map = dynamic(() => import('./Map/Map'));
const ReverseGeocodingMap = dynamic(() =>
  import('./ReverseGeocodingMap/ReverseGeocodingMap')
);
const JsonEditor = dynamic(() => import('./JsonEditor/JsonEditor'));
const NoAccess = dynamic(() => import('./NoAccess/NoAccess'));
const Dialog = dynamic(() => import('./Dialog/Dialog'));
const PricingForm = dynamic(() => import('./PricingForm/PricingForm'));
const FiltersModal = dynamic(() => import('./FiltersModal/FiltersModal'));
const Modal = dynamic(() => import('./Modal/Modal'));
const ToggleIconButtons = dynamic(() =>
  import('./ToggleIconButtons/ToggleIconButtons')
);
const KanbanBoard = dynamic(() => import('./KanbanBoard/KanbanBoard'));
const ActivityList = dynamic(() => import('./ActivityList/ActivityList'));
const MoreButton = dynamic(() => import('./MoreButton/MoreButton'));
const GridCellFee = dynamic(() => import('./GridCellFee/GridCellFee'));
const GridCellDateNow = dynamic(() =>
  import('./GridCellDateNow/GridCellDateNow')
);
const GridSearch = dynamic(() => import('./GridSearch/GridSearch'));
const Stepper = dynamic(() => import('./Stepper/Stepper'));
const WebCam = dynamic(() => import('./WebCam/WebCam'));
const Accordion = dynamic(() => import('./Accordion/Accordion'));
const SignatureModal = dynamic(() => import('./SignatureModal/SignatureModal'));
const LanguageSwitcher = dynamic(() =>
  import('./LanguageSwitcher/LanguageSwitcher')
);
const DownloadButton = dynamic(() => import('./DownloadButton/DownloadButton'));

const InfoTooltip = dynamic(() => import('./InfoTooltip/InfoTooltip'));
const Text = dynamic(() => import('./Text/Text'));
const UploadDocument = dynamic(() => import('./UploadDocument/UploadDocument'));
const UploadDocumentModal = dynamic(() =>
  import('./UploadDocumentModal/UploadDocumentModal')
);
const Comments = dynamic(() => import('./Comments/Comments'));
const PermissionsGate = dynamic(() =>
  import('./PermissionsGate/PermissionsGate')
);
const Block = dynamic(() => import('./Block/Block'));
const TenantSelection = dynamic(() =>
  import('./TenantSelection/TenantSelection')
);
const CatalogMedia = dynamic(() => import('./CatalogMedia/CatalogMedia'));
export { UploadImage } from './UploadImage';
export { DynamicForm } from './DynamicForm';
export { FormModal } from './FormModal';

const Tabs = dynamic(() => import('./Tabs/Tabs'));
const TabContent = dynamic(() => import('./Tabs/TabContent'));
const TabsContainer = dynamic(() => import('./Tabs/TabsContainer'));
const InputFieldWithLabel = dynamic(() =>
  import('./InputFieldWithLabel/InputFieldWithLabel')
);
const CatalogDocuments = dynamic(() =>
  import('./CatalogDocuments/CatalogDocuments')
);
const Clipboard = dynamic(() => import('./Clipboard/Clipboard'));
const DataGridPremium = dynamic(() =>
  import('./DataGridPremium/DataGridPremium')
);

const ArnmWorkTypeField = dynamic(() =>
  import('./ArnmWorkTypeField/ArnmWorkTypeField')
);
const DrawerMenu = dynamic(() => import('./DrawerMenu/DrawerMenu'));
const Loader = dynamic(() => import('./Loader/Loader'));
const Calendar = dynamic(() => import('./Calendar/Calendar'));
const FormDialog = dynamic(() => import('./FormDialog/FormDialog'));
const MentionsComment = dynamic(() =>
  import('./MentionsComment/MentionsComment')
);
const ButtonV2 = dynamic(() => import('./ButtonV2/ButtonV2'));
const IconButtonV2 = dynamic(() => import('./IconButtonV2/IconButtonV2'));
const CheckBox = dynamic(() => import('./CheckBox/CheckBox'));
const Switch = dynamic(() => import('./Switch/Switch'));
const BreadcrumpsV2 = dynamic(() => import('./BreadcrumpsV2/BreadcrumpsV2'));
const TabsV2 = dynamic(() => import('./TabsV2/TabsV2'));
const UploadImageV3 = dynamic(() => import('./UploadImageV3/UploadImageV3'));
const UploadCSV = dynamic(() => import('./UploadCSV/UploadCSV'));
const SimpleMenu = dynamic(() => import('./SimpleMenu/SimpleMenu'));
const ImagePreview = dynamic(() => import('./ImagePreview/ImagePreview'));
const UploadInput = dynamic(() => import('./UploadInput/UploadInput'));
const UploadDocumentV3 = dynamic(() =>
  import('./UploadDocumentV3/UploadDocumentV3')
);
const FileList = dynamic(() => import('./FileList/FileList'));
const KanbanBoardV2 = dynamic(() => import('./KanbanBoardV2/KanbanBoardV2'));
const InputFieldV2 = dynamic(() => import('./InputFieldV2/InputFieldV2'));
const ToggleIconButtonsV2 = dynamic(() =>
  import('./ToggleIconButtonsV2/ToggleIconButtonsV2')
);
const FiltersModalV2 = dynamic(() => import('./FiltersModalV2/FiltersModalV2'));
const ManageGridColumns = dynamic(() =>
  import('./ManageGridColumns/ManageGridColumns')
);
const CommentsV2 = dynamic(() => import('./CommentsV2/CommentsV2'));
const MentionsCommentV2 = dynamic(() =>
  import('./MentionsCommentV2/MentionsCommentV2')
);
const Label = dynamic(() => import('./Label/Label'));
const GridCellLinkV2 = dynamic(() => import('./GridCellLinkV2/GridCellLinkV2'));
const PaginationV2 = dynamic(() => import('./PaginationV2/PaginationV2'));
const FieldV2 = dynamic(() => import('./FieldV2/FieldV2'));
const SmallName = dynamic(() => import('./SmallName/SmallName'));
const HistoryV2 = dynamic(() => import('./HistoryV2/HistoryV2'));
const StageChip = dynamic(() => import('./StageChip/StageChip'));
const TaskSection = dynamic(() => import('./TaskSection/TaskSection'), {
  ssr: false,
});
const ActivityListV2 = dynamic(() => import('./ActivityListV2/ActivityListV2'));
const BoardShortName = dynamic(() => import('./BoardShortName/BoardShortName'));
const ShortName = dynamic(() => import('./ShortName/ShortName'));
const TaskCarDetails = dynamic(() => import('./TaskCarDetails/TaskCarDetails'));
const TaskStages = dynamic(() => import('./TaskStages/TaskStages'));
const ChangeBoardModal = dynamic(() =>
  import('./ChangeBoardModal/ChangeBoardModal')
);

const ArnmRepairWorkTypeField = dynamic(() =>
  import('./ArnmRepairWorkTypeField/ArnmRepairWorkTypeField')
);
const EditARNMFileModal = dynamic(() =>
  import('./EditARNMFileModal/EditARNMFileModal')
);
const UploadARNMImageV2 = dynamic(() =>
  import('./UploadARNMImageV2/UploadARNMImageV2')
);
const UploadARNMDocumentV2 = dynamic(() =>
  import('./UploadARNMDocumentV2/UploadARNMDocumentV2')
);
const Tooltip = dynamic(() => import('./Tooltip/Tooltip'));
const ModalV2 = dynamic(() => import('./ModalV2/ModalV2'));
const ModalFormSection = dynamic(() => import('./ModalV2/ModalFormSection'));

const TaskDetailScrollableSection = dynamic(() =>
  import('./TaskDetailScrollableSection/TaskDetailScrollableSection')
);
const ListPageFiltersWrapper = dynamic(() =>
  import('./ListPageFiltersWrapper/ListPageFiltersWrapper')
);
const ListPageTabs = dynamic(() => import('./ListPageTabs/ListPageTabs'));

export {
  FileList,
  UploadDocumentV3,
  UploadInput,
  PaginationV2,
  Label,
  GridCellLinkV2,
  FieldV2,
  ButtonV2,
  IconButtonV2,
  CheckBox,
  Switch,
  BreadcrumpsV2,
  TabsV2,
  Accordion,
  ActivityList,
  Block,
  Breadcrumps,
  Button,
  Comments,
  CSVGrid,
  DataGrid,
  Dialog,
  DownloadButton,
  EmptyContent,
  Expanded,
  ExpandedItem,
  ExpandItem,
  FileDropZone,
  FilterSearchBar,
  FiltersModal,
  GlobalSearch,
  GridCellActiveStatus,
  GridCellBoolean,
  GridCellDateNow,
  GridCellExpand,
  GridCellFee,
  GridCellLink,
  GridSearch,
  IconButton,
  Image,
  InfoTooltip,
  InputField,
  JsonEditor,
  JsonList,
  KanbanBoard,
  LanguageSwitcher,
  Link,
  LocationSearch,
  SearchAddress,
  Logo,
  LogoBreadcrump,
  Map,
  ReverseGeocodingMap,
  Modal,
  MoreButton,
  NoAccess,
  Pagination,
  Paragraph,
  PermissionsGate,
  PricingForm,
  RouteGuard,
  SignatureModal,
  Stepper,
  SubTitle,
  TenantSelection,
  Text,
  Title,
  ToggleButton,
  ToggleIconButtons,
  UploadDocument,
  UploadDocumentModal,
  WebCam,
  CatalogMedia,
  InputFieldWithLabel,
  Tabs,
  TabContent,
  TabsContainer,
  CatalogDocuments,
  Clipboard,
  ArnmWorkTypeField,
  DataGridPremium,
  DrawerMenu,
  Loader,
  Calendar,
  FormDialog,
  MentionsComment,
  UploadImageV3,
  SimpleMenu,
  ImagePreview,
  KanbanBoardV2,
  InputFieldV2,
  ToggleIconButtonsV2,
  FiltersModalV2,
  ManageGridColumns,
  CommentsV2,
  MentionsCommentV2,
  SmallName,
  HistoryV2,
  StageChip,
  TaskSection,
  ActivityListV2,
  ShortName,
  BoardShortName,
  TaskCarDetails,
  TaskStages,
  UploadCSV,
  ChangeBoardModal,
  ArnmRepairWorkTypeField,
  EditARNMFileModal,
  UploadARNMImageV2,
  UploadARNMDocumentV2,
  Tooltip,
  ModalV2,
  ModalFormSection,
  TaskDetailScrollableSection,
  ListPageFiltersWrapper,
  ListPageTabs,
};
/* PLOP_INJECT_COMPONENT_EXPORT */

export { FlagsProvider, useFlags } from './FlagsProvider';

export * from './Vectors';
export { UploadARNMImage } from './UploadARNMImage';
export { UploadImageV2 } from './UploadImageV2';
export { UploadDocumentV2 } from './UploadDocumentV2';
export { PopoverColorPicker } from './PopoverColorPicker';
export { UploadARNMDocument } from './UploadARNMDocument';
/* PLOP_INJECT_COMPONENT_EXPORT */
