import theme from 'config/theme';

export const DEFLEET_PAGE_SIZE = 1000;

export const DEFLEET_TABS = {
  defleet: {
    label: 'defleet',
    key: 'defleet',
    type: 16,
    id: 'DEF',
    statuses: {
      0: {
        label: 'Backlog',
      },
      65: {
        label: 'Email Sent',
      },
      66: {
        label: 'First Call',
      },
      67: {
        label: 'Proposal Sent',
      },
      68: {
        label: 'Follow-Up Call',
      },
      69: {
        label: 'Final Outcome',
      },
      70: {
        label: 'Instapayment',
      },
      3: {
        label: 'Done',
      },
      12: {
        label: 'Cancelled',
      },
    },
    statusOrder: [0, 65, 66, 67, 68, 69, 70, 3, 12],
  },
};

export const DEFLEET_OUTCOMES_API = {
  vehicleBuyout: 'Vehicle Buyout',
  leaseRenewal: 'Lease Renewal',
  vehicleReturn: 'Vehicle Return',
};

export const DEFLEET_TASK_STATUSES_NAMES = {
  0: {
    label: 'Backlog',
    styles: {
      backgroundColor: theme.palette.grays.gray200,
      color: theme.palette.grays.gray900,
    },
  },
  65: {
    label: 'Email Sent',
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  66: {
    label: 'First Call',
    styles: {
      backgroundColor: theme.palette.violets.violet200,
      color: theme.palette.violets.violet900,
    },
  },
  67: {
    label: 'Proposal Sent',
    styles: {
      backgroundColor: theme.palette.limes.lime200,
      color: theme.palette.limes.lime900,
    },
  },
  68: {
    label: 'Follow-Up Call',
    styles: {
      backgroundColor: theme.palette.fukshias.fukshia200,
      color: theme.palette.fukshias.fukshia900,
    },
  },
  69: {
    label: 'Final Outcome',
    styles: {
      backgroundColor: theme.palette.babyBlues.babyBlue200,
      color: theme.palette.babyBlues.babyBlue900,
    },
  },
  70: {
    label: 'Instapayment',
    styles: {
      backgroundColor: theme.palette.teals.teal200,
      color: theme.palette.teals.teal900,
    },
  },
  3: {
    label: 'Done',
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.green900,
    },
  },
  12: {
    label: 'Cancelled',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
};

export const DEFLEET_OUTCOME_NAMES = {
  vehicleBuyout: {
    label: 'Vehicle Buyout',
    styles: {
      backgroundColor: theme.palette.blues.primaryBlue200,
      color: theme.palette.blues.primaryBlue800,
      width: 'max-content',
      borderRadius: '4px',
    },
  },
  leaseRenewal: {
    label: 'Lease Renewal',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
      width: 'max-content',
      borderRadius: '4px',
    },
  },
  vehicleReturn: {
    label: 'Vehicle Return',
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
      width: 'max-content',
      borderRadius: '4px',
    },
  },
};

export const TARGET_OPTIONS = [
  {
    text: 'Q1',
    value: 'Q1',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
  {
    text: 'Q2',
    value: 'Q2',
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.green900,
    },
  },
  {
    text: 'Q3',
    value: 'Q3',
    styles: {
      backgroundColor: theme.palette.purples.purple200,
      color: theme.palette.purples.purple900,
    },
  },
  {
    text: 'Q4',
    value: 'Q4',
    styles: {
      backgroundColor: theme.palette.pinks.pink200,
      color: theme.palette.pinks.pink900,
    },
  },
  {
    text: 'H1',
    value: 'H1',
    styles: {
      backgroundColor: theme.palette.teals.teal200,
      color: theme.palette.teals.teal900,
    },
  },
  {
    text: 'H2',
    value: 'H2',
    styles: {
      backgroundColor: theme.palette.limes.lime200,
      color: theme.palette.limes.lime900,
    },
  },
];

export const DEFLEET_TASK_FIELD_NAMES = {
  outcome: 'Outcome Status',
  target: 'Target',
  status: 'Status',
  assignee: 'Assignee',
  promisedReturnDate: 'Promised Return Date',
  returnDate: 'Return Date',
  returnPoint: 'Return Point',
};
