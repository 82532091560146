import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
// Create a theme instance.
const inputFontSize = '14px';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6500',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#C7C7C7',
    },
    magenta: {
      main: '#6d3dca',
      contrastText: '#ffffff',
    },
    stepper: {
      main: '#D9D9D9',
      contrastText: '#ffffff',
    },
    stepperActive: {
      main: '#00A9B3',
      contrastText: '#ffffff',
    },
    black: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    lightGray: {
      main: '#F8F8F8',
      contrastText: '#5A5A5A',
    },
    mediumGray: {
      main: '#e7e1e1',
      contrastText: '#5A5A5A',
    },
    darkGray: {
      main: '#5A5A5A',
      contrastText: '#ffffff',
    },
    onlyTextTeal: {
      main: '#007F86',
      contrastText: '#007F86',
    },
    disabled: {
      main: '#dedede',
      contrastText: '#007F86',
    },
    error: {
      main: red.A400,
    },
    white: {
      paper: '#FFFFFF',
      default: 'black',
    },
    backgroundGray: {
      paper: '#E9E9E8',
      default: '#E9E9E8',
    },
    green: {
      ribbon: '#00b124',
      primary: '#00BB00',
    },
    blue: {
      ribbon: '#15c8d5',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
    dropdownArrow: {
      main: 'rgba(0,0,0,0.54)',
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(0,0,0,0.6)',
      magenta: '#ffffff',
      disabled: 'rgba(0,0,0,0.38)',
      gray: '#3b4863',
    },
    link: {
      blue: '#0168fa',
      darkBlue: '#073f99',
    },
    approved: {
      main: '#03AA46',
    },
    rejected: {
      main: '#FF0000',
    },
    pending: {
      main: '#FFA366',
    },
    hoverTeal: '#ecfeff',
    divider: 'rgba(0,0,0,0.08)',
    separator: '#ededf0',
    grays: {
      lightestGray: '#F8FAFC',
      gray100: '#F1F5F9',
      gray200: '#E2E8F0',
      gray300: '#CBD5E1',
      gray400: '#94A3B8',
      gray500: '#64748B',
      gray600: '#475569',
      gray700: '#334155',
      gray900: '#0F172A',
    },
    blues: {
      main: '#3B82F6',
      primaryBlue: '#3B82F6',
      primaryBlue100: '#DBEAFE',
      primaryBlue200: '#BFDBFE',
      primaryBlue300: '#93C5FD',
      primaryBlue400: '#60A5FA',
      primaryBlue500: '#3B82F6',
      primaryBlue600: '#2563EB',
      primaryBlue700: '#1654DD',
      primaryBlue800: '#1E3A8A',
      babyBlue200: '#BAE6FD',
      babyBlue900: '#0C4A6E',
      lightestBlue: '#EFF6FF',
      blue100: '#DBEAFE',
      blue200: '#BFDBFE',
      blue600: '#2563EB',
      blue900: '#0D1F54',
      gray400: '#94A3B8',
      gray500: '#64748B',
      gray600: '#475569',
      gray700: '#334155',
      gray900: '#0F172A',
    },
    violets: {
      violet200: '#DDD6FE',
      violet400: '#A78BFA',
      violet900: '#4C1D95',
    },
    fukshias: {
      fukshia200: '#F5D0FE',
      fukshia400: '#E879F9',
      fukshia900: '#701A75',
    },
    oranges: {
      orange200: '#FED7AA',
      orange400: '#FB923C',
      orange900: '#631F07',
    },
    yellows: {
      yellow200: '#FFF7BA',
      yellow400: '#FDE047',
      yellow900: '#68380D',
    },
    greens: {
      green200: '#BBF7D0',
      green400: '#4ADE80',
      green900: '#14532D',
      codingGreen400: '#4ADE80',
      codingGreen200: '#BBF7D0',
    },
    limes: {
      lime200: '#D9F99D',
      lime900: '#365314',
    },
    reds: {
      red200: '#FECACA',
      red900: '#7F1D1D',
      codingRed200: '#FECACA',
      codingRed400: '#F87171',
      codingRed900: '#7F1D1D',
    },
    teals: {
      teal200: '#A5F3FC',
      teal900: '#164E63',
    },
    mints: {
      mint200: '#99F6E4',
      mint900: '#134E4A',
    },
    lines: {
      line200: '#D9F99D',
      line900: '#365314',
    },
    purples: {
      purple200: '#C7D2FE',
      purple900: '#312E81',
    },
    pinks: {
      pink200: '#FBCFE8',
      pink900: '#831843',
    },
    babyBlues: {
      babyBlue200: '#BAE6FD',
      babyBlue900: '#0C4A6E',
    },
  },
  typography: {
    fontFamily: ['Proxima Nova', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  customValues: {
    maxTaskPageWidth: '1400px',
    maxBasicPageWidth: '1080px',
    topBarHeight: 40,
  },
  customShadows: {
    lightShadow: '0px 4px 7px 0px rgba(0, 0, 0, 0.10)',
    errorShadow: '1px 1px 4px 0px rgba(248, 113, 113, 0.30)',
    successShadow: '1px 1px 2px 0px rgba(34, 197, 94, 0.30)',
    errorState: '1px 1px 4px 0px rgba(248, 113, 113, 0.30)',
    successState: '1px 1px 2px 0px rgba(34, 197, 94, 0.30)',
    bottomShadow: '0px 6px 12px 0px rgba(5, 57, 170, 0.21)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: '40px',
          borderRadius: '8px',
          fontSize: inputFontSize,
          fontWeight: 'bold',
          ...(ownerState.variant === 'secondary' && {
            border: '1px solid #FF6500',
          }),
        }),
      },
      defaultProps: {
        disableElevation: true,
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: inputFontSize,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          minWidth: '34px',
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: inputFontSize,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '14px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          display: 'contents',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          border: `1px solid #C7C7C7`,
          borderRadius: '8px',
          overflow: 'hidden',
        },
        paper: {
          borderRadius: 0,
        },
      },
    },
  },
});
export default theme;
