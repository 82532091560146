import moment from 'moment';
import { formatDate } from '@utils';
import { REASONS_OF_REJECTION } from '@constants';

const _computeOfferRequestColor = (offerRequestDate) => {
  if (offerRequestDate) {
    const given = moment(offerRequestDate, 'YYYY-MM-DD');
    const current = moment().startOf('day');
    const dayDiff = moment.duration(current.diff(given)).asDays();
    if (dayDiff >= 35) {
      return '#FFA366';
    }
    return '#03AA46';
  }
  if (!offerRequestDate) {
    return '#FF0000';
  }
};

export const handleSellRequestsData = (data, assignees) => {
  if (!('tasksByColumn' in data)) {
    return {};
  }
  const tasks = [];
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.id] = {
      ...cur,
      shortName: `${cur.firstName?.[0]}${cur.lastName?.[0]}`.toUpperCase(),
    };
    return acc;
  }, {});
  // eslint-disable-next-line no-unused-vars
  data?.tasksByColumn?.forEach((item, index) => {
    const newTasks = item?.tasks?.map((task) => {
      const assignee = assigneesMap?.[task.assignee] || {};

      return {
        ...task,
        href: `/sellRequests/${task?.id}`,
        status: task?.status,
        assignee,
        description: task?.name,
        createdAt: task?.createdAt
          ? formatDate(task?.createdAt, 'DD/MM/YYYY')
          : '-',
        userId: task?.customFields?.userId,
        name: task?.name || '',
        finalPrice: task?.customFields?.finalPrice?.number
          ? new Intl.NumberFormat('el-GR', {
              style: 'currency',
              currency: 'EUR',
            }).format(task?.customFields?.finalPrice?.number)
          : '-',
        offerSentDate: task?.customFields?.offerSentDate
          ? formatDate(task?.customFields?.offerSentDate, 'DD/MM/YYYY')
          : '-',
        offerRequestDateColor: _computeOfferRequestColor(
          task?.customFields?.offerRequestDate
        ),
        approvalStatus: !task?.customFields?.approvalStatus
          ? 'Pending'
          : task?.customFields?.approvalStatus,
        loan: task?.customFields?.loan,
        loanContractId: task?.customFields?.loanContractId || '-',
      };
    });
    tasks.push(...newTasks);
  });
  // data?.statuses
  //   ?.sort((a, b) => b.order - a.order)
  //   ?.reverse()
  //   ?.forEach((item) => {
  //     columns.push({
  //       id: item.status,
  //       title: SELL_REQUESTS_TASK_STATUSES_NAMES[item.status],
  //       cardIds: data?.tasksByColumn
  //         ?.filter((ent) => {
  //           return (
  //             ent?.columnName ===
  //             SELL_REQUESTS_TASK_STATUSES_SERVER_NAMES[item.status]
  //           );
  //         })
  //         ?.pop()
  //         ?.tasks?.map((task) => task?.id),
  //     });
  //   });

  return {
    boardId: data?.id,
    tasks,
  };
};
const handleAmmountInstallmentsItem = (newAmountItem) => {
  if (newAmountItem.Key === 'amount') {
    return {
      ...newAmountItem,
      Value: newAmountItem.Value.map((amountValueItem) => ({
        ...amountValueItem,
        Value:
          amountValueItem.Key === 'number'
            ? amountValueItem.Value / 100
            : amountValueItem.Value,
      })),
    };
  }
  return newAmountItem;
};

export const handleAssigneeData = (data = []) =>
  data
    ?.sort((a, b) => {
      return a.firstName.localeCompare(b.firstName);
    })
    ?.map((item) => ({
      isActive: item?.isActive,
      email: item.email,
      value: item.id,
      role: item.role,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));

export const handleSellRequestsTaskData = (data, assignees = []) => {
  if (!data) {
    return {};
  }
  let assignee = null;
  if (data?.assignee) {
    assignee = assignees?.find((user) => {
      return user?.id === data?.assignee;
    });
    assignee = {
      isActive: assignee?.isActive,
      text: assignee?.firstName
        ? `${assignee?.firstName} ${assignee?.lastName}`
        : assignee?.email,
      value: assignee?.id,
    };
  }

  const changelog = data?.changelog?.reverse()?.map((item) => {
    const normalisedNewValue =
      item.fieldName === 'installments'
        ? item.newValue.map((newValue) =>
            newValue.map(handleAmmountInstallmentsItem)
          )
        : item.newValue;

    return { ...item, newValue: normalisedNewValue };
  });

  const rejectionReason = () => {
    const reason = REASONS_OF_REJECTION.find(
      (item) => item.value === data?.customFields?.finalPriceRejectionReason
    );
    if (!reason) {
      return {
        finalPriceRejectionReason: 'Other',
        finalPriceRejectionReasonOther:
          data?.customFields?.finalPriceRejectionReason,
      };
    }
    return {
      finalPriceRejectionReason: data?.customFields?.finalPriceRejectionReason,
      finalPriceRejectionReasonOther: '',
    };
  };

  const task = {
    ...data,
    assignee,
    customFields: {
      ...data?.customFields,
      approvalStatus: data?.customFields?.approvalStatus || 'Pending',
      finalPrice: data?.customFields?.finalPrice?.number,
      transferOfOwnershipFee:
        data?.customFields?.transferOfOwnershipFee?.number,
      offerSentDate: data?.customFields?.offerSentDate
        ? formatDate(data?.customFields?.offerSentDate, 'DD-MM-YYYY')
        : '',
      offerExpirationDate: data?.customFields?.offerExpirationDate
        ? formatDate(data?.customFields?.offerExpirationDate, 'DD-MM-YYYY')
        : '',
      nbv: data?.customFields?.nbv?.number,
      vehiclePurchasePrice: data?.customFields?.vehiclePurchasePrice?.number,
      extraCap: data?.customFields?.extraCap?.number,
      minimumPrice: data?.customFields?.minimumPrice?.number,
      commercialAssessment: data?.customFields?.commercialAssessment?.number,
      comparablePrice: data?.customFields?.comparablePrice?.number,
      loanRequestStatus: data?.customFields?.loanRequestStatus || 'Pending',
      administrativeExpenses:
        data?.customFields?.administrativeExpenses?.number,
      downpayment: data?.customFields?.downpayment?.number,
      financedAmount: data?.customFields?.financedAmount?.number,
      offerRequestDate: data?.customFields?.offerRequestDate
        ? formatDate(data?.customFields?.offerRequestDate, 'DD-MM-YYYY')
        : '',
      ...rejectionReason(),
    },
    changelog,
    comments: data?.comments?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY, HH:mm')
          : '',
      };
    }),
    documents: data?.documents?.map((document) => {
      return { ...document, cannotDelete: document?.type === 'BuyoutOffer' };
    }),
  };
  return task;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.sku?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${
      data?.vehicleInternalId ? `${data?.vehicleInternalId} -` : '' || ''
    } ${data?.vin ? `${data?.vin} -` : ''} ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${data?.sku?.transmission ? `${data?.sku?.transmission} -` : ''} ${
      data?.sku?.engine?.fuelType
    }`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
    licenseIssuanceDate: formatDate(
      data?.procurement?.licenseIssuanceDate,
      'DD-MM-YYYY'
    ),
  };
  return vehicle;
};
