import { pick, omit } from 'lodash';
import { FILTER_RULE_ENUMS, FILTER_OPERATOR_ENUMS, LIMIT } from '@constants';

export const getOrdersRequestQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  return pick(payload, ['page', 'pageSize', 'order', 'orderBy']);
};

export const getOrdersRequestPayload = (payload = {}) => {
  const fields = omit(payload, ['page', 'pageSize', 'order', 'orderBy']) || {};
  const numberFields = ['status'];
  const multyFilterStringFields = [];
  const booleanFields = [];
  const booleanValues = {
    true: true,
    false: false,
  };

  const _getValue = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (booleanFields.includes(field)) {
      return booleanValues[payload[field]];
    }
    return numberFields.includes(field)
      ? Number(payload[field])
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }

    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  if (value.length && fields.status) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
