import { formatDate } from '@utils';

export const handleErrorData = (array) => {
  if (!array) {
    return [];
  }
  const data = array?.map((item) => {
    return {
      createdAt: item.createdAt
        ? formatDate(item.created_at, 'DD/MM/YYYY')
        : '-',
      dealStage: item.dealStage || '-',
      elorusContactId: item.elorusContactId || '-',
      message: item.message || '-',
      organizationId: item.organizationId || '-',
      type: item.type || '-',
      userEmail: item.userEmail || '-',
      id: item._id,
    };
  });
  return data;
};
