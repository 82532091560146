import { pick } from 'lodash';
import { FILTER_RULE_ENUMS, FILTER_OPERATOR_ENUMS, LIMIT } from '@constants';

export const getDriversePartnersRequestQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  return pick(payload, ['page', 'pageSize', 'order', 'orderBy']);
};

export const getDriversePartnersRequestPayload = (payload = {}) => {
  const { searchTerm } = payload;

  const value = [];

  if (searchTerm) {
    const filedsToSearch = ['id', 'name'];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
