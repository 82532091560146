export const getFuelType = (enums, fuelType, prop = 'name') => {
  if (enums?.fuelTypes?.length) {
    const enumFuelType = enums?.fuelTypes?.find((item) => {
      return item?.[prop] === fuelType;
    });
    return enumFuelType?.id;
  }
  return null;
};

export const getCategory = (enums, category, prop = 'name') => {
  if (enums?.categories?.length) {
    const enumCategories = enums?.categories?.find((item) => {
      return item?.[prop] === category;
    });
    return enumCategories?.id;
  }
  return null;
};

export const getEuroClass = (enums, euroClass, prop = 'name') => {
  if (enums?.euroClasses?.length) {
    const enumEuroclasses = enums?.euroClasses?.find((item) => {
      return item?.[prop] === euroClass;
    });
    return enumEuroclasses?.id;
  }
  return null;
};

export const getAvailability = (enums, availability, prop = 'name') => {
  if (enums?.availabilities?.length) {
    const enumAvailability = enums?.availabilities?.find((item) => {
      return item?.[prop] === availability;
    });
    return enumAvailability?.id;
  }
  return null;
};

export const getEngineType = (enums, engineType, prop = 'name') => {
  if (enums?.engineTypes?.length) {
    const enumEngineTypes = enums?.engineTypes?.find((item) => {
      return item?.[prop] === engineType;
    });
    return enumEngineTypes?.id;
  }
  return null;
};

export const getTransmission = (enums, transmission, prop = 'name') => {
  if (enums?.transmissions?.length) {
    const enumTransmissions = enums?.transmissions?.find((item) => {
      return item?.[prop] === transmission;
    });
    return enumTransmissions?.id;
  }
  return null;
};

export const getBodyType = (enums, bodyType, prop = 'name') => {
  if (enums?.bodyTypes?.length) {
    const enumBodyTypes = enums?.bodyTypes?.find((item) => {
      return item?.[prop] === bodyType;
    });
    return enumBodyTypes?.id;
  }
  return null;
};

export const getBodySize = (enums, bodySize, prop = 'name') => {
  if (enums?.bodySizes?.length) {
    const enumBodySize = enums?.bodySizes?.find((item) => {
      return item?.[prop] === bodySize;
    });
    return enumBodySize?.id;
  }
  return null;
};

export const getProductLines = (enums, productLine, prop = 'name') => {
  if (enums?.productLines?.length) {
    const enumProductLine = enums?.productLines?.find((item) => {
      return item?.[prop] === productLine;
    });
    return enumProductLine?.id;
  }
  return null;
};

export const getDealer = (enums, dealer, prop = 'name') => {
  if (enums?.dealers?.length) {
    const enumDealer = enums?.dealers?.find((item) => {
      return item?.[prop] === dealer;
    });
    return enumDealer?.id;
  }
  return null;
};
