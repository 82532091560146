import dynamic from 'next/dynamic';

const DefleetGrid = dynamic(() => import('./DefleetGrid/DefleetGrid'));
const DefleetFilter = dynamic(() => import('./DefleetFilter/DefleetFilter'));
const DefleetListContent = dynamic(() => import('./DefleetListContent'));
const DetailsForm = dynamic(() => import('./DetailsForm/DetailsForm'));
const DefleetTicketDetails = dynamic(() =>
  import('./DefleetTicketDetails/DefleetTicketDetails')
);
const DefleetUserDetails = dynamic(() =>
  import('./DefleetUserDetails/DefleetUserDetails')
);

export {
  DefleetGrid,
  DefleetFilter,
  DefleetListContent,
  DetailsForm,
  DefleetTicketDetails,
  DefleetUserDetails,
};
