const formatVehicleStage = (stageId) => {
  const field = {
    0: 'Created',
    1: 'Order',
    2: 'Customs',
    3: 'Payment',
    4: 'Dealer',
    5: 'Fleet',
    6: 'Defleeted',
    7: 'Cancelled',
  };
  return field[stageId] || '-';
};

export default formatVehicleStage;
