/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { driverseServices } from '@services';
import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';

export const useDriverseCampaignOperations = () => {
  const {
    back,
    query: { campaignId },
  } = useRouter();

  const campaign = useQuery(
    [QUERY_KEYS.GET_DRIVERSE_CAMPAIGN_BY_ID_KEY, campaignId],
    () => driverseServices?.getDriverseCampaignById(campaignId),
    {
      enabled: !!campaignId,
      refetchOnMount: 'always',
      fetchPolicy: 'cache-and-network',
    }
  );

  const coupons = useQuery(
    [QUERY_KEYS.GET_DRIVERSE_COUPONS_BY_CAMPAIGN_ID_KEY, campaignId],
    () =>
      driverseServices.getDriverseCoupons({
        params: { page: 1, pageSize: 1000 },
        payload: {
          field: 'campaignId',
          operator: FILTER_OPERATOR_ENUMS['='],
          type: FILTER_RULE_ENUMS.CompareStringRuleType,
          value: campaignId,
        },
      }),
    {
      enabled: !!campaignId,
      refetchOnMount: 'always',
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (campaign.error) {
      showError(campaign.error);
    }
  }, [campaign.error]);

  return {
    loading: campaign.isLoading,
    campaignId,
    campaign: campaign?.data?.data,
    remainingCoupons: coupons?.data?.data?.filter?.((item) => !item?.isUsed)
      ?.length,
    getCampaign: campaign?.refetch,
    fetchCoupons: coupons?.refetch,
    goBack: back,
  };
};
