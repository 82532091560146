import { pickBy } from 'lodash';
import { URLS } from 'layouts/DrawerLayout/constants';
import { formatDate } from '@utils';

export const getCleanedUrlParams = (params) =>
  pickBy(params, (item) => item !== null && item !== undefined && item !== '');

export const getUrl = (params) => {
  const urlParams = getCleanedUrlParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `${URLS.campaigns}?${searchParams}`;
};

export const normalizeCampaigns = (data = []) => {
  if (!data.length > 0) {
    return {};
  }

  const campaigns = data.map((item) => {
    const currentDate = new Date().toISOString();

    return {
      ...item,
      id: item?.id,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
      createdBy: item?.createdBy || '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      updatedBy: item?.updatedBy || '-',
      startingAt: item?.startingAt
        ? formatDate(item?.startingAt, 'DD/MM/YYYY HH:mm')
        : '-',
      endingAt: item?.endingAt
        ? formatDate(item?.endingAt, 'DD/MM/YYYY HH:mm')
        : '-',
      description: item?.description || '-',
      isActive:
        item?.endingAt === null ||
        (currentDate > item?.startingAt && currentDate < item?.endingAt),
    };
  });

  return campaigns;
};
