/* eslint-disable react/no-array-index-key */
import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { css } from 'aphrodite';
import {
  Button,
  IconButton,
  InputField,
  PlusIcon,
  SubTitle,
} from '@components';
import { Box, Typography } from '@mui/material';
import { generateShortId, showError } from '@utils';

import styles from './dynamicForm.style';
import { DeleteIcon } from '../Vectors';

const DynamicForm = forwardRef(
  (
    {
      id,
      onSubmit,
      loading,
      formScheme,
      onFormStateChange,
      defaultValues,
      fieldsToWatch,
      withErrorLabel,
      showErrorList,
      formStyle,
      disabled,
      selectedTabOption,
      customStyles,
      isGrid,
      onAddMore,
      onRemove,
      noFormBorder,
      validationMode = 'onChange',
    },
    ref
  ) => {
    const {
      reset,
      handleSubmit,
      control,
      getValues,
      setValue,
      watch,
      formState: { errors },
    } = useForm({
      mode: validationMode,
      reValidateMode: validationMode,
      defaultValues,
      resolver: undefined,
      context: undefined,
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: false,
      delayError: undefined,
    });
    const fields = watch(fieldsToWatch);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      _reset(values) {
        reset(values);
      },
      _setValue(key, value = '') {
        setValue(key, value);
      },
      values: getValues(),
    }));

    useEffect(() => {
      if (onFormStateChange) {
        onFormStateChange(getValues());
      }
    }, [fields, onFormStateChange, getValues]);

    useEffect(() => {
      const newErrorList = [];
      Object.keys(errors).forEach((field) => {
        return newErrorList.push(field);
      });
      if (newErrorList?.length && showErrorList) {
        showError(
          `Please update the following fields: ${newErrorList.join(', ')}`
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(errors)]);

    const formInputs = formScheme?.sections?.map((section, sectionIndex) => {
      let displayValue = 'flex';
      if (section?.tab) {
        if (section.tab !== selectedTabOption) {
          displayValue = 'none';
        }
      }
      return (
        <Box
          id={section?.id || generateShortId()}
          key={`section-${sectionIndex}`}
          className={
            customStyles &&
            css(!noFormBorder && styles.sectionWrapper, styles[section?.area])
          }
          sx={{
            ...(!customStyles && section?.sectionWrapperStyle),
            ...(customStyles &&
              section?.forceSectionWrapperStyle &&
              section?.sectionWrapperStyle),
            display: displayValue,
            maxWidth: section?.maxWidth || '100%',
          }}
        >
          {section?.title && (
            <Box
              key={`titleWrapper-${sectionIndex}`}
              sx={{
                borderBottom: (theme) =>
                  customStyles
                    ? `1px solid ${theme.palette.secondary.main}`
                    : '1px solid lightgray',
                paddingBottom: customStyles && '8px',
                ...section?.titleStyles,
              }}
            >
              <SubTitle
                sx={{
                  fontWeight: section?.fontWeight,
                }}
              >
                {t(section?.title) || ''}
              </SubTitle>
            </Box>
          )}
          <Box
            sx={{
              ...section?.rows?.rowWrapperStyle,
              paddingTop: customStyles ? '8px' : '12px',
            }}
          >
            {section?.rows?.data?.map((row, rowIndex) => {
              return (
                <Box
                  key={row?.id || `row-${rowIndex}`}
                  sx={{
                    ...section?.rows?.rowWrapperStyle,
                    padding: customStyles ? '0' : '12px 0',
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <SubTitle sx={row.rowTitleStyle}>
                      {row.alternativeTitle !== undefined
                        ? t(row.alternativeTitle)
                        : t(row?.title)}
                    </SubTitle>
                    <Box sx={row?.rowWrapperStyle}>
                      {row?.data?.map((column, columnIndex) => {
                        return (
                          <Box
                            key={`input-${columnIndex}`}
                            sx={
                              (!customStyles && row?.columnWrapperStyle) ||
                              (customStyles &&
                                row?.forceColumnWrapperStyle &&
                                row?.columnWrapperStyle)
                            }
                          >
                            {Object.keys(column).map((input, inputIndex) => {
                              const {
                                rules,
                                defaultValue,
                                label,
                                alternativeLabel,
                                fieldWrapperStyle,
                                inputWrapperStyle,
                                allCaps,
                                instructions,
                                hidden,
                                allowNegative = false,
                              } = column[input];
                              if (hidden) return null;
                              return (
                                !hidden && (
                                  <Box
                                    key={`inputWrapper-${inputIndex}`}
                                    sx={fieldWrapperStyle}
                                  >
                                    <Controller
                                      name={input}
                                      control={control}
                                      rules={rules}
                                      defaultValue={defaultValue}
                                      render={({
                                        onBlur,
                                        field,
                                        fieldState,
                                        ...props
                                      }) => (
                                        <div
                                          className={
                                            customStyles &&
                                            css(styles.inputWrapperStyle)
                                          }
                                          style={inputWrapperStyle}
                                        >
                                          {(label || alternativeLabel) && (
                                            <Typography
                                              sx={{
                                                fontSize: '14px',
                                                fontWeight: customStyles
                                                  ? '700'
                                                  : '400',
                                                lineHeight: '23px',
                                                color: (theme) =>
                                                  customStyles
                                                    ? theme.palette.darkGray
                                                        .main
                                                    : theme.palette.black.main,
                                                marginBottom: customStyles
                                                  ? '0px'
                                                  : '8px',
                                                marginTop: '0',
                                                borderBottom: (theme) =>
                                                  customStyles &&
                                                  `1px solid ${theme.palette.backgroundGray.default}`,
                                                minWidth: customStyles && '50%',
                                                width: customStyles
                                                  ? '100%'
                                                  : 'auto',
                                              }}
                                            >
                                              {(customStyles &&
                                                alternativeLabel &&
                                                ` ${t(alternativeLabel)}`) ||
                                                (label && ` ${t(label)}`)}
                                              {rules?.required && (
                                                <Typography
                                                  variant="span"
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette.error.main,
                                                  }}
                                                >
                                                  *
                                                </Typography>
                                              )}
                                            </Typography>
                                          )}
                                          <InputField
                                            name={input}
                                            value={
                                              allCaps
                                                ? field.value.toUpperCase()
                                                : field.value
                                            }
                                            loading={loading}
                                            disabled={disabled || loading}
                                            onChange={field.onChange}
                                            {...column[input]}
                                            {...props}
                                            error={!!fieldState.error}
                                            setValue={setValue}
                                            alternative={customStyles}
                                            allowNegative={allowNegative}
                                          />
                                        </div>
                                      )}
                                    />
                                    {instructions && (
                                      <span className={css(styles.instruction)}>
                                        {instructions}
                                      </span>
                                    )}
                                    {withErrorLabel && errors[input] && (
                                      <span className={css(styles.error)}>
                                        {`${errors[input].message}`}
                                      </span>
                                    )}
                                  </Box>
                                )
                              );
                            })}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  {row.withInnerRemove && onRemove && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '12px',
                        marginBottom: 2,
                        fontSize: '0.875rem',
                        color: (theme) => theme.palette.darkGray.main,
                      }}
                    >
                      {section?.removeLabel}
                      <IconButton
                        sx={{
                          '&:hover': {
                            '& .delete-icon-path, & .delete-icon-path-2, & .delete-icon-line, & .delete-icon-line-2, & .delete-icon-line-3':
                              {
                                stroke: (theme) => theme.palette.error.main,
                              },
                          },
                        }}
                        onClick={() => onRemove(row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            {section?.withAddMore && (
              <Button
                color="addMore"
                width="max-content"
                onClick={() => onAddMore(section)}
              >
                {section?.addMoreLabel}
                <PlusIcon />
              </Button>
            )}
            {section?.withRemove && onRemove && (
              <Button
                color="secondary"
                width="max-content"
                onClick={() => onRemove(section)}
              >
                {section?.removeLabel}
              </Button>
            )}
          </Box>
        </Box>
      );
    });

    return (
      <form
        id={id || 'dynamic-form'}
        onSubmit={handleSubmit(onSubmit)}
        style={customStyles ? undefined : formStyle}
        className={customStyles && isGrid && css(styles.formWrapper)}
      >
        {formInputs}
      </form>
    );
  }
);

const memoizedDynamicForm = React.memo(DynamicForm);
export { memoizedDynamicForm as DynamicForm };

export default memoizedDynamicForm;
