import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { groupBy } from 'lodash';

import { boardsServices, agentsServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

import { ARNM_REPAIRS_TASK_STATUSES_NAMES } from 'components/arnmRepairs/constants';
import {
  handleArnmRepairsData,
  handleAssigneeData,
} from './arnmRepairs.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  arnmRepairs: [],
  vehicle: {},
  servicePoints: [],
  assignees: [],
  total: 0,
  pageSize: 1000,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const arnmRepairsSlice = createSlice({
  name: 'arnmRepairs',
  initialState,
  reducers: {
    fetchArnmRepairs: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
    },
    fetchArnmRepairsSuccess: (state, { payload }) => {
      state.arnmRepairs = handleArnmRepairsData(
        payload?.response.data,
        payload?.assignees?.data
      );
      state.pageSize = payload?.response?.meta?.pageSize;
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.assignees = handleAssigneeData(payload?.assignees?.data);
    },
    fetchArnmRepairsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    fetchArnmRepairAssignees: (state) => {
      state.assignees.loading = true;
      state.assignees.hasErrors = false;
      state.assignees.errorMessage = '';
    },
    fetchArnmRepairAssigneesSuccess: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = handleAssigneeData(payload.assignees);
    },
    fetchArnmRepairAssigneesFailure: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = [];
      state.assignees.hasErrors = true;
      state.assignees.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchArnmRepairs,
  fetchArnmRepairsSuccess,
  fetchArnmRepairsFailure,
  fetchArnmRepairAssignees,
  fetchArnmRepairAssigneesSuccess,
  fetchArnmRepairAssigneesFailure,
} = arnmRepairsSlice.actions;

export const selectArnmRepairsState = (state) => state.arnmRepairs;
export const selectArnmRepairsColumnsState = ({ arnmRepairs }) => {
  const { tasks } = arnmRepairs?.arnmRepairs || {};
  const order = [0, 2, 13, 14, 15, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');
  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const getArnmRepairs = (payload) => {
  return async (dispatch) => {
    dispatch(fetchArnmRepairs());
    const page = parseInt(payload?.page, 10);

    try {
      const response = await boardsServices.getBoardsFilters({ ...payload });
      response.meta.page = page;
      response.meta.pageSize = payload.pageSize;
      const assignees = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      dispatch(fetchArnmRepairsSuccess({ response, assignees }));
    } catch (error) {
      dispatch(fetchArnmRepairsFailure(error));
    }
  };
};

export default arnmRepairsSlice.reducer;
