/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { generateShortId, readFile, showError } from '@utils';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { useToggleModal } from './useToggleModal';

export const useArnmFiles = ({
  files,
  loading,
  shouldChangeOnDelete,
  onChange,
  onDeleteFile,
}) => {
  const [localFiles, setLocalFiles] = useState(files);
  const { isOpen, handleClose, handleOpen } = useToggleModal();
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (files?.length) {
      setLocalFiles([...files]);
    }
  }, [files]);

  const handleOnchange = async (newFiles) => {
    try {
      if (loading) return;
      const fileArray = [...newFiles];
      const data = await Promise.all(
        fileArray.map(async (file) => {
          const fileContents = await readFile(file);
          fileContents.id = generateShortId();
          fileContents.localFile = true;
          return fileContents;
        })
      );
      setLocalFiles([...localFiles, ...data]);
      const filesToUpLoad = localFiles?.filter((doc) => {
        return doc?.localFile;
      });
      onChange([...filesToUpLoad, ...data]);
    } catch (error) {
      showError(error || GENERIC_ERROR_MESSAGE);
    }
  };

  const handleDeleteFile = async (file) => {
    try {
      if (!file.localFile) {
        await onDeleteFile(file?.id);
      }
      const newFiles = localFiles?.filter((doc) => {
        return file?.id !== doc?.id;
      });
      setLocalFiles(newFiles);
      if (shouldChangeOnDelete) {
        onChange(newFiles);
      }
    } catch (error) {
      showError(error?.message);
    }
  };

  const handleOpenEditFile = (file) => {
    handleOpen();
    setSelectedFile(file);
  };

  const handleEditFile = (values) => {
    const tags = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc.push(cur);
      } else {
        acc = acc.filter((item) => item !== cur);
      }
      return acc;
    }, []);
    let newLocalFiles = cloneDeep(localFiles);

    newLocalFiles = newLocalFiles?.map((item) => {
      if (item?.id === selectedFile?.id) {
        return {
          ...selectedFile,
          isPatched: true,
          tags,
        };
      }
      return item;
    });
    onChange(newLocalFiles);
    setLocalFiles(newLocalFiles);
    handleClose();
  };

  return {
    isOpen,
    localFiles,
    selectedFile,
    handleOpenEditFile,
    handleEditFile,
    handleDeleteFile,
    handleOnchange,
    handleCloseEditFile: handleClose,
  };
};
