import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { groupBy } from 'lodash';

import { boardsServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

import { DEFLEET_TASK_STATUSES_NAMES } from 'hooks/defleet/constants';
import { handleDefleetData } from './defleet.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  defleet: [],
  defleetTask: {},
  total: 0,
  pageSize: 1000,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const defleetSlice = createSlice({
  name: 'defleet',
  initialState,
  reducers: {
    fetchDefleet: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.defleetTask = {};
    },
    fetchDefleetSuccess: (state, { payload }) => {
      state.defleet = handleDefleetData(payload?.response.data);
      state.pageSize = payload?.response?.meta?.pageSize;
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchDefleetFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchDefleet, fetchDefleetSuccess, fetchDefleetFailure } =
  defleetSlice.actions;

export const selectDefleetState = (state) => state.defleet;
export const selectDefleetColumnsState = ({ defleet }) => {
  const { tasks } = defleet?.defleet || {};
  const order = [0, 2, 13, 14, 15, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');
  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: DEFLEET_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: DEFLEET_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: DEFLEET_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const getDefleet = (payload) => {
  return async (dispatch) => {
    dispatch(fetchDefleet());
    const page = parseInt(payload?.page, 10);

    try {
      const response = await boardsServices.getBoardsFilters({ ...payload });
      response.meta.page = page;
      response.meta.pageSize = payload.pageSize;
      dispatch(fetchDefleetSuccess({ response }));
    } catch (error) {
      dispatch(fetchDefleetFailure(error));
    }
  };
};

export default defleetSlice.reducer;
