import React, { useEffect } from 'react';
import Head from 'next/head';
import { appWithI18Next } from 'ni18n';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { useStore } from 'react-redux';
import { FlagsProvider, RouteGuard } from '@components';
import { LicenseInfo } from '@mui/x-license-pro';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import theme from '../config/theme';
import createEmotionCache from '../config/createEmotionCache';
import '../styles/globals.css';
import '../styles/proximaNova.css';

import 'react-toastify/dist/ReactToastify.css';
import { ni18nConfig } from '../ni18n.config';

import { wrapper } from '../store';

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY);

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) {
  const [mounted, setMounted] = React.useState(false);
  const getLayout = Component.getLayout || ((page) => page);
  const defaults =
    typeof window !== 'undefined' &&
    JSON.parse(localStorage.getItem('firebaseFlags'));

  useEffect(() => {
    setMounted(true);
  }, []);
  const store = useStore();

  return (
    <PersistGate persistor={store.__persistor}>
      <CacheProvider value={emotionCache}>
        <QueryClientProvider client={queryClient}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ visibility: mounted ? 'visible' : 'hidden' }}>
              <RouteGuard>
                <FlagsProvider defaults={defaults || {}}>
                  {getLayout(<Component {...pageProps} />)}
                </FlagsProvider>
              </RouteGuard>
              <ToastContainer />
            </div>
          </ThemeProvider>
        </QueryClientProvider>
      </CacheProvider>
    </PersistGate>
  );
}
export default appWithI18Next(wrapper.withRedux(MyApp), ni18nConfig);
