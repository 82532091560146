import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { agentsServices, serviceVehiclesServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { getAgentsRequestPayload } from 'hooks/agents/utils';
import {
  handleServiceVehiclesData,
  handleServiceVehicleData,
  handleAgentsData,
} from './serviceVehicles.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  serviceVehicles: [],
  serviceVehicle: {},
  agents: [],
  serviceVehiclesTypes: {},
  total: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const serviceVehiclesSlice = createSlice({
  name: 'serviceVehicles',
  initialState,
  reducers: {
    fetchServiceVehicles: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServiceVehiclesSuccess: (state, { payload }) => {
      state.serviceVehicles = handleServiceVehiclesData(payload);
      state.serviceVehiclesTypes = payload?.serviceVehiclesTypes;
      state.total = payload?.serviceVehicles?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServiceVehiclesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchServiceVehicle: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.eventsLoading = false;
      state.events = [];
      state.errorMessage = '';
    },
    fetchServiceVehicleSuccess: (state, { payload }) => {
      state.serviceVehicle = handleServiceVehicleData(
        payload?.serviceVehicle,
        payload.serviceVehiclesTypes
      );
      state.serviceVehiclesTypes = payload?.serviceVehiclesTypes;
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServiceVehicleAgentsSuccess: (state, { payload }) => {
      state.agents = handleAgentsData(payload?.data);
    },
    fetchServiceVehicleFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.eventsLoading = false;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchServiceVehicles,
  fetchServiceVehiclesSuccess,
  fetchServiceVehiclesFailure,
  fetchServiceVehicle,
  fetchServiceVehicleSuccess,
  fetchServiceVehicleAgentsSuccess,
  fetchServiceVehicleFailure,
} = serviceVehiclesSlice.actions;

export const selectServiceVehiclesState = (state) => state.serviceVehicles;

export const getServiceVehicles = (payload) => {
  return async (dispatch) => {
    dispatch(fetchServiceVehicles());

    try {
      const data = {};
      const page = parseInt(payload?.page, 10);
      const serviceVehicles =
        await serviceVehiclesServices?.getServiceVehiclesFilters(payload);
      serviceVehicles.meta.page = page;
      const serviceVehiclesTypes =
        await serviceVehiclesServices?.getServiceVehiclesTypes();
      data.serviceVehicles = serviceVehicles;
      data.serviceVehiclesTypes = serviceVehiclesTypes?.data;

      dispatch(fetchServiceVehiclesSuccess(data));
    } catch (error) {
      dispatch(fetchServiceVehiclesFailure(error));
    }
  };
};

export const getServiceVehicle = (id) => {
  return async (dispatch) => {
    dispatch(fetchServiceVehicle());

    try {
      const data = {};
      const serviceVehicle = await serviceVehiclesServices?.getServiceVehicle(
        id
      );
      const serviceVehiclesTypes =
        await serviceVehiclesServices?.getServiceVehiclesTypes();
      data.serviceVehicle = serviceVehicle?.data;
      data.serviceVehiclesTypes = serviceVehiclesTypes?.data;
      const agents = await agentsServices.getFilterAgents({
        params: {
          page: 1,
          pageSize: 1000,
        },
        payload: getAgentsRequestPayload({}),
      });
      dispatch(fetchServiceVehicleSuccess(data));
      dispatch(fetchServiceVehicleAgentsSuccess(agents));
    } catch (error) {
      dispatch(fetchServiceVehicleFailure(error));
    }
  };
};

export default serviceVehiclesSlice.reducer;
