/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { driverseServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import {
  getDriversePartnersRequestQueryParams,
  getDriversePartnersRequestPayload,
} from 'helpers';
import { handleDriverseCampaigns } from 'store/driverseCampaigns/driverseCampaigns.handlers';
import {
  handleDriversePartners,
  handleDriversePartner,
} from './driversePartners.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  data: [],
  partner: {},
  total: 0,
  pageSize: LIMIT,
  filters: {
    urlParams: undefined,
  },
};

// Actual Slice
export const driversePartnersSlice = createSlice({
  name: 'driversePartners',
  initialState,
  reducers: {
    fetchDriversePartners: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.data = [];
      state.partner = {};
    },
    fetchDriversePartnersSuccess: (state, { payload }) => {
      state.data = handleDriversePartners(payload?.response?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.total = payload?.response?.meta?.total || 0;
      state.filters.urlParams = payload.urlParams;
    },
    fetchDriversePartnersFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchDriversePartnerSuccess: (state, { payload }) => {
      state.partner = handleDriversePartner(payload?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchDriversePartners,
  fetchDriversePartnersSuccess,
  fetchDriversePartnersFailure,
  fetchDriversePartnerSuccess,
} = driversePartnersSlice.actions;

export const getDriversePartners = ({ urlParams = {} }) => {
  return async (dispatch) => {
    dispatch(fetchDriversePartners());
    try {
      const params = getDriversePartnersRequestQueryParams(urlParams);
      const payload = getDriversePartnersRequestPayload(urlParams);

      const response = await driverseServices?.getDriverseMembers({
        params,
        payload,
      });

      response.meta.page = urlParams.page || 1;

      dispatch(
        fetchDriversePartnersSuccess({
          response,
          urlParams,
        })
      );
    } catch (error) {
      dispatch(fetchDriversePartnersFailure(error));
    }
  };
};

export const getDriversePartner = (memberId) => {
  return async (dispatch) => {
    dispatch(fetchDriversePartners());
    try {
      const response = await driverseServices?.getDriverseMemberById(memberId);

      dispatch(fetchDriversePartnerSuccess(response));
    } catch (error) {
      dispatch(fetchDriversePartnersFailure(error));
    }
  };
};

export const selectDriversePartnersState = (state) => state.driversePartners;

export default driversePartnersSlice.reducer;
