import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FormModal, Link } from '@components';
import { showError } from '@utils';
import { agentsServices } from '@services';
import { AGENT_ROLES } from '@constants';
import { editAgentModalForm } from './editAgentModalForm';

const EditAgentModal = ({ getAgents, agent }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(editAgentModalForm || {});
  const modalRef = useRef(null);
  let { user } = localStorage;
  if (user) {
    user = JSON.parse(user);
  }
  const _toggleModal = () => {
    setOpen(!open);
  };

  const _initializeForm = useCallback(() => {
    setForm(editAgentModalForm);
    const newForm = cloneDeep(form);
    if (user?.role === AGENT_ROLES.isAdmin) {
      newForm.sections[0].rows.data[0].data[2].isActive.disabled = false;
    } else {
      newForm.sections[0].rows.data[0].data[2].isActive.disabled = true;
    }
    setForm(newForm);
    setTimeout(() => {
      modalRef.current._setValue('firstName', agent?.firstName);
      modalRef.current._setValue('lastName', agent?.lastName);
      modalRef.current._setValue('email', agent?.email);
      modalRef.current._setValue('role', agent?.role);
      modalRef.current._setValue('isActive', agent?.isActive);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  const _onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        role: data?.role,
        isActive: data?.isActive,
      };

      await agentsServices.editAgent(agent?.id, payload);
      await getAgents();
      _toggleModal();
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      _initializeForm();
    }
  }, [open, _initializeForm]);

  return (
    <>
      <Box color="primary" onClick={_toggleModal}>
        <Link text={agent?.id} />
      </Box>
      <FormModal
        ref={modalRef}
        id="add-agent-modal"
        title="Edit Agent"
        open={open}
        formScheme={form}
        loading={loading}
        handleClose={_toggleModal}
        onSubmit={_onSubmit}
        withCancel
        size="sm"
        submitText="Add"
      />
    </>
  );
};

export default EditAgentModal;
