import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useGridApiRef } from '@mui/x-data-grid-premium';
import { useRouter } from 'next/router';

export const useFleetGridState = ({ saveGridState }) => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const router = useRouter();
  const stage = useRef(router.query.stage);

  useEffect(() => {
    stage.current = router.query.stage;
  }, [router.query.stage]);

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();

      dispatch(
        saveGridState({
          stage: stage.current || 'all',
          gridState: currentState,
        })
      );
    }
  }, [apiRef, dispatch, saveGridState]);

  useLayoutEffect(() => {
    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  return { apiRef, saveSnapshot };
};

export default useFleetGridState;
