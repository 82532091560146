import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { fleetVehiclesServices, vehiclesServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { VEHICLES_PAGE_SIZE } from 'hooks/vehicles/useFleetVehiclesOperations';
import { handleVehicles, handleEnums } from './fleetVehicles.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  vehicles: [],
  total: 0,
  firstItem: 1,
  lastItem: VEHICLES_PAGE_SIZE,
  pageCount: 0,
  pageSize: VEHICLES_PAGE_SIZE,
  filters: {
    stage: 'all',
    searchTerm: '',
  },
  ordering: {
    orderBy: '',
    order: '',
  },
  enums: {
    errorMessage: '',
    loading: false,
    data: {},
  },
};
// Actual Slice
export const fleetVehiclesSlice = createSlice({
  name: 'fleetVehicles',
  initialState,
  reducers: {
    fetchFleetVehicles: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchFleetVehiclesSuccess: (state, { payload }) => {
      state.vehicles = handleVehicles(payload?.response?.data);
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.response?.meta?.page === 1
          ? 1
          : ((payload?.response?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.response?.meta?.page === 1
          ? state.vehicles.length
          : ((payload?.response?.meta?.page || 1) - 1) * state.pageSize +
            (state?.vehicles?.length || 0);
      state.pageCount =
        payload?.response?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.response?.meta?.total || 1) / state.pageSize);
      state.filters = {
        stage: payload.params.stage,
        searchTerm: payload.params.searchTerm || '',
      };
      state.ordering = {
        order: payload.params.order || '',
        orderBy: payload.params.orderBy || '',
      };
    },
    fetchFleetVehiclesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    changeSearchTermFilter: (state, { payload }) => {
      state.filters.searchTerm = payload;
    },
    fetchFleetVehiclesEnums: (state) => {
      state.enums.loading = true;
      state.enums.errorMessage = '';
    },
    fetchFleetVehiclesEnumsSuccess: (state, { payload }) => {
      state.enums.data = handleEnums(payload);
      state.enums.loading = false;
      state.enums.errorMessage = '';
    },
    fetchFleetVehiclesEnumsFailure: (state, { payload }) => {
      state.enums.loading = false;
      state.enums.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchFleetVehicles,
  fetchFleetVehiclesSuccess,
  fetchFleetVehiclesFailure,
  changeSearchTermFilter,
  fetchFleetVehiclesEnums,
  fetchFleetVehiclesEnumsSuccess,
  fetchFleetVehiclesEnumsFailure,
} = fleetVehiclesSlice.actions;

export const selectFleetVehiclesState = (state) => state.fleetVehicles;

export const getFleetVehicles = (payload) => {
  return async (dispatch) => {
    dispatch(fetchFleetVehicles());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await fleetVehiclesServices?.getVehicles({
        ...payload,
        pageSize: VEHICLES_PAGE_SIZE,
      });
      response.meta.page = page;
      dispatch(fetchFleetVehiclesSuccess({ response, params: payload }));
    } catch (error) {
      dispatch(fetchFleetVehiclesFailure(error));
    }
  };
};

export const getFleetVehiclesEnums = () => {
  return async (dispatch) => {
    dispatch(fetchFleetVehiclesEnums());

    try {
      const results = await Promise.all([
        fleetVehiclesServices.getVehicleSourcingTypes(),
        fleetVehiclesServices.getVehicleDealers(),
        fleetVehiclesServices.getVehicleColors(),
        fleetVehiclesServices.getInsurances(),
        vehiclesServices.getVehiclesEnums(),
      ]);

      dispatch(fetchFleetVehiclesEnumsSuccess(results));
    } catch (error) {
      dispatch(fetchFleetVehiclesEnumsFailure(error));
    }
  };
};

export const changeSearchTerm = (payload) => {
  return (dispatch) => {
    dispatch(changeSearchTermFilter(payload));
  };
};

export default fleetVehiclesSlice.reducer;
