import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { driversServices } from '@services';
import { DRIVERS_TABS, GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { handleInspectionedCars } from './inspectionCars.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  vehicles: [],
  groups: {},
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  page: 1,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const inspectionCarsSlice = createSlice({
  name: 'inspectionedCars',
  initialState,
  reducers: {
    fetchInspectionedCars: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInspectionedCarsSuccess: (state, { payload }) => {
      state.vehicles = payload?.inspection?.data || [];
      state.groups = handleInspectionedCars(
        payload?.inspection?.data,
        payload?.groupType
      );
      state.page = payload?.inspection?.meta?.page || 1;
      state.total = payload?.inspection?.meta?.total || 0;
      state.pageCount =
        payload?.inspection?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.inspection?.meta?.total || 1) / state.pageSize);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInspectionedCarsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchMoreInspectionedCarsSuccess: (state, { payload }) => {
      const stateItems = state.vehicles;

      state.vehicles = [...stateItems, ...payload.inspection.data];
      state.groups = handleInspectionedCars(
        [...stateItems, ...payload.inspection.data],
        payload?.groupType
      );
      state.page = payload?.inspection?.meta?.page || 1;
      state.total = payload?.inspection?.meta?.total || 0;
      state.pageCount =
        payload?.inspection?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.inspection?.meta?.total || 1) / state.pageSize);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    inspectionChangePage: (state, { payload }) => {
      state.page = payload;
    },
    inspectionInitState: (state) => {
      state.groups = {};
      state.vehicles = [];
      state.page = 1;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchInspectionedCars,
  fetchInspectionedCarsSuccess,
  fetchInspectionedCarsFailure,
  fetchMoreInspectionedCarsSuccess,
  inspectionChangePage,
  inspectionInitState,
} = inspectionCarsSlice.actions;

export const selectInspectionedCarsState = (state) => state.inspectionedCars;

export const getInspectionedCars = (payload) => {
  return async (dispatch) => {
    dispatch(fetchInspectionedCars());

    const page = parseInt(payload?.page, 10);
    try {
      const response = await driversServices?.getInspectedCars({
        ...payload,
        searchFor: DRIVERS_TABS.inspections,
      });
      response.meta.page = page;

      dispatch(
        fetchInspectionedCarsSuccess({
          inspection: response,
          groupType: 'inspectionDate',
        })
      );
    } catch (error) {
      dispatch(fetchInspectionedCarsFailure(error));
    }
  };
};

export const getMoreInspectionedCars = (payload) => {
  return async (dispatch) => {
    dispatch(fetchInspectionedCars());
    const page = parseInt(payload?.page, 10);
    try {
      const response = await driversServices?.getInspectedCars({
        ...payload,
        searchFor: DRIVERS_TABS.inspections,
      });
      response.meta.page = page;

      dispatch(
        fetchMoreInspectionedCarsSuccess({
          inspection: response,
          groupType: 'inspectionDate',
        })
      );
    } catch (error) {
      dispatch(fetchInspectionedCarsFailure(error));
    }
  };
};

export default inspectionCarsSlice.reducer;
