import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getSellRequestsTask, selectSellRequestsState } from '@store';
import { showError } from '@utils';

export const useSellRequestsTaskOperations = () => {
  const {
    sellRequestsTask,
    vehicle,
    pageSize,
    loading,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectSellRequestsState);
  const {
    query: { sellRequestsTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getSellRequestsTask = useCallback(async () => {
    await dispatch(getSellRequestsTask(sellRequestsTaskId));
  }, [sellRequestsTaskId, dispatch]);

  const mentionedUsers = assignees?.map?.((user) => {
    return { id: user?.email, display: user?.text };
  });

  useEffect(() => {
    if (sellRequestsTaskId !== undefined) {
      _getSellRequestsTask();
    }
  }, [sellRequestsTaskId, _getSellRequestsTask, token]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    vehicle,
    task: sellRequestsTask,
    taskId: sellRequestsTaskId,
    mentionedUsers,
    assignees,
    hasErrors,
    errorMessage,
    token,
    pageSize,
    getSellRequestsTask: _getSellRequestsTask,
  };
};
