import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { marketingServices } from '@services';
import { LIMIT } from '@constants';
import { getUrl, normalizeCampaigns } from './utils';

export const useCampaignsOperations = () => {
  const router = useRouter();
  const pageSize = LIMIT;

  const page = router.query?.page || 1;
  const order = router.query?.order;
  const orderBy = router.query?.orderBy;
  const token = router?.query?.token;

  const campaignsResponse = useQuery(
    [QUERY_KEYS.GET_CAMPAIGNS_KEY, { orderBy, order, page }],
    () =>
      // TODO: Replace this with getCleanedUrlParams from utils
      marketingServices.getCampaigns({
        orderBy: orderBy || 'createdAt',
        order: order || 'desc',
        page: page || '1',
      })
  );

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel });
    router.push(url);
  };

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }));
  };

  const campaigns = normalizeCampaigns(campaignsResponse?.data?.data || []);

  useEffect(() => {
    if (campaignsResponse.isError) {
      showError(campaignsResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsResponse.isError]);

  return {
    page,
    pageSize,
    total: campaignsResponse?.data?.meta?.total,
    loading: campaignsResponse?.isLoading,
    campaigns,
    hasErrors: campaignsResponse?.isError,
    errorMessage: campaignsResponse?.error,
    getCampaigns: campaignsResponse.refetch,
    nextPage: handlePageChange,
    token,
    sortBy: handleSortBy,
  };
};
