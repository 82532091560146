/* eslint-disable */
import { groupBy, isEmpty, omit, pick } from 'lodash';

import { formatDate, getCleanedUrlParams } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import theme from 'config/theme';

import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import { BILLING_PAGE_SIZE, BILLING_TABS } from './constants';

export const getColumns = (tasks = [], board = '') => {
  const order = BILLING_TABS[board].statusOrder;
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: BILLING_TABS[board]?.statuses?.[cur]?.label || cur,
      cards: groupedByStatus[cur] || [],
      styles: {
        backgroundColor: theme.palette.blues.babyBlue200,
        color: theme.palette.blues.babyBlue900,
      },
      value: true,
      label: BILLING_TABS[board]?.statuses?.[cur]?.label || cur,
      key: cur,
    });

    return acc;
  }, []);

  return columns;
};

export const normaliseBillingTasks = (data = [], assignees = []) => {
  if (!data.length > 0) {
    return {};
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0]}${cur.lastName?.[0]}`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = data.map((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    return {
      ...item,
      href: `${URLS.billing}/${item?.id}`,
      plate: item.customFields?.vehiclePlate || '',
      status: item?.status,
      assignee,
      description: item?.customFields?.vehicleDescription || '-',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      id: item?.id,
      title: item.customFields?.title || '',
    };
  });

  return tasks;
};

export const normaliseAssigneeData = (data = []) =>
  data
    .map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
      shortName:
        item.firstName && item.lastName
          ? `${item?.firstName[0].toUpperCase()}${item?.lastName[0].toUpperCase()}`
          : item.email[0].toUpperCase(),
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

export const getBillingQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = BILLING_PAGE_SIZE;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  return getCleanedUrlParams(
    pick(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'boardType',
      'assignee',
      'searchTerm',
    ])
  );
};

export const getBillingRequestPayload = (payload = {}) => {
  const fields =
    omit(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'boardType',
      'searchTerm',
    ]) || {};
  const { searchTerm } = payload;

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: FILTER_RULE_ENUMS.ComparableRuleType,
      value: payload[field],
    })),
  ];

  if (searchTerm) {
    const filedsToSearch = ['subscriptionId', 'title', 'carId', 'vehiclePlate'];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field: `customFields.${field}`,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  return {
    boardType: payload.boardType,
    type: FILTER_RULE_ENUMS.AndRuleType,
    field: null,
    operator: null,
    value,
  };
};

const getValue = (data, current) => {
  return (
    data.find((item) => item?.value === current) || {
      value: '',
      text: '',
    }
  );
};

const getBoard = (data, current) => {
  return (
    data.find((item) => item?.id === current) || {
      value: '',
      text: '',
    }
  );
};
export const handleBillingTaskData = ({
  data = {},
  assignees = [],
  stages = [],
  boards = [],
}) => {
  if (!data || isEmpty(data)) {
    return {};
  }
  const boardId = data.id?.split('-')?.[0];
  const assignee = getValue(assignees, data.assignee);
  const status = getValue(stages, data.status);
  const board = getBoard(boards, boardId);

  const task = {
    ...data,
    assignee,
    status,
    board,
    changelog: data?.changelog?.reverse(),
    customFields: {
      ...omit(data.customFields, ['comments']),
    },
    subscriptionOwner: data.customFields.userFirstName
      ? `${data.customFields.userFirstName} ${data.customFields.userLastName}`
      : '',
    comments: data?.comments?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
  };
  return task;
};

export const normalizeVehicleData = (data = {}) => {
  const properties = [];

  if (data.properties?.fuelType) {
    properties.push(data.properties.fuelType);
  }
  if (data.properties?.transmission) {
    properties.push(data.properties.transmission);
  }
  if (data.properties?.color) {
    properties.push(data.properties.color);
  }
  if (data.year) {
    properties.push(data.year);
  }
  return {
    internalId: data.internalId,
    id: data.id,
    title: `${data.manufacturer ? `${data.manufacturer}, ` : ''}${
      data.model || ''
    }`,
    plate: data.plate,
    vin: data.vin,
    image: data?.images?.[0]?.url,
    properties,
    procurement: data.procurement,
  };
};

export const getPayload = (values) => {
  Object.keys(values)?.forEach((key) => {
    if (values[key] === '') {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'documents',
    'tags',
    'status',
    'updatedAt',
    'updatedBy',
    'changelog',
    'images',
    'parent',
    'name',
    'createdAt',
    'comments',
    'createdBy',
  ]);

  return {
    // assignee: values.assignee?.value,
    customFields: {
      ...cleanedValues.customFields,
    },
  };
};
