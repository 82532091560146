export const QUERY_KEYS = {
  GET_BILLING_KEY: 'billing',
  GET_BILLING_TASK_KEY: 'billing-task',
  GET_BILLING_INSTACAR_KEY: 'billing-instacar',
  GET_BILLING_INSTARIDE_KEY: 'billing-instaride',
  GET_BILLING_REFUNDS_KEY: 'billing-refunds',
  GET_BILLING_DAMAGES_KEY: 'billing-damages',
  GET_BILLING_OTHER_KEY: 'billing-other',
  GET_BILLING_ASSIGNEES_KEY: 'billing-assignees',
  GET_CAMPAIGNS_KEY: 'campaigns',
  GET_VEHICLE_BY_INTERNAL_ID_KEY: 'vehicle-by-internal-id',
  GET_DRIVERSE_CAMPAIGNS_BY_PARTNER_ID_KEY: 'driverse-campaigns-by-driverse-id',
  GET_DRIVERSE_CAMPAIGN_BY_ID_KEY: 'driverse-campaign-by-id',
  GET_DRIVERSE_COUPONS_BY_CAMPAIGN_ID_KEY: 'driverse-coupons-by-campaign-id',
  GET_ARNM_REPAIRS_TASK_KEY: 'arnmRepairs-task',
  GET_SERVICE_POINTS_KEY: 'service-points',
  GET_ORDERS_KEY: 'orders-listing',
  GET_LOGGER_KEY: 'error-list',
  GET_AGENTS_KEY: 'agents',
  GET_PARTNERS_KEY: 'partners',
  GET_USERS_KEY: 'users-list',
  GET_PERMISSIONS_KEY: 'permissions',
  GET_CONFIGURATION_KEY: 'configuration',
  GET_ROADSIDE_ASSISTANCES_KEY: 'roadside-assistances',
  GET_SERVICE_POINTS_ENUMS_KEY: 'service-points-enums',
  GET_DEFLEET_KEY: 'defleet',
  GET_DEFLEET_ASSIGNEES_KEY: 'defleet-assignees',
  GET_DEFLEET_TASK_KEY: 'defleet-task',
  GET_DEFLEET_USER_KEY: 'defleet-user',
};
