import { omit } from 'lodash';
import { SELL_REQUESTS_STATUSES_NAMES_MAP } from 'components/sellRequests/constants';
import { showWarning } from '@utils';

export const sellRequestsPayload = (values, task, taskId) => {
  const clearableFields = [];

  Object.keys(values)?.forEach((key) => {
    if (values[key] === '' && !clearableFields.includes(key)) {
      values[key] = null;
    }
  });

  if (values.finalPriceRejectionReasonOther) {
    values.finalPriceRejectionReason = values.finalPriceRejectionReasonOther;
  }
  if (values?.interestRate) {
    values.interestRate = parseFloat(values?.interestRate);
  }
  if (values?.marginPercentage) {
    values.marginPercentage = parseFloat(values?.marginPercentage);
  }

  if (
    values.loan &&
    values.loanRequestStatus !== 'Rejected' &&
    !values.loanDisbursed &&
    values.purchaseInvoice &&
    task.status === SELL_REQUESTS_STATUSES_NAMES_MAP.VehicleSales
  ) {
    showWarning(`Loan disbursement for ${taskId} pending`);
  }

  values.depositChecklist = {
    depositConfirmation: values?.depositConfirmation || false,
    liftingOfWithholding: values?.liftingOfWithholding || false,
  };

  values.forSaleChecklist = {
    kteo: values?.kteo || false,
    purchaseInvoice: values?.purchaseInvoice || false,
    invoice: values?.invoice || false,
    vehicleTransferAuthorization: values?.vehicleTransferAuthorization || false,
    certificateOfUnpaidTrafficFees:
      values?.certificateOfUnpaidTrafficFees || false,
  };

  values.transferChecklist = {
    disableSecurity: values?.disableSecurity || false,
    vehicleDefleeted: values?.vehicleDefleeted || false,
    scheduleDeliveryOfLicenseAndSecondKey:
      values?.scheduleDeliveryOfLicenseAndSecondKey || false,
    copyOfLicenseSending: values?.copyOfLicenseSending || false,
  };

  if (!values?.loan) {
    delete values.transferChecklist.copyOfLicenseSending;
  }
  if (values?.approvalStatus === 'Pending') {
    delete values.approvalStatus;
  }

  const cleanedValues = omit(values, [
    'createdAt',
    'customFields.offerExpirationDate',
    'customFields.vehicleLeaseStartDate',
    'customFields.offerSentDate',
    'customFields.finalPriceRejectionReasonOther',
  ]);
  return {
    id: taskId,
    assignee: cleanedValues?.assignee?.value,
    customFields: {
      ...cleanedValues.customFields,
      marginPercentage: Number(cleanedValues.customFields.marginPercentage),
      interestRate: Number(cleanedValues.customFields.interestRate),
      payoutPlanMonths:
        cleanedValues.customFields?.payoutPlanMonths &&
        Number(cleanedValues.customFields?.payoutPlanMonths),
      payoutMonthlyInstallment:
        cleanedValues.customFields?.payoutMonthlyInstallment?.number &&
        `${cleanedValues.customFields?.payoutMonthlyInstallment?.number}`,
    },
  };
};
