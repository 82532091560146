/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { formatDate, generateShortId } from '@utils';
import { IconButton, Image, PermissionsGate, Text } from '@components';
import _ from 'lodash';

const ImageItem = ({
  image,
  setImage,
  toggleFormModal,
  toggleShowImage,
  loading,
  onDeleteImage,
  connectDragSource,
  connectDropTarget,
}) => {
  const _setImage = (img) => {
    if (setImage) {
      setImage(img);
    }
  };

  const _toggleFormModal = () => {
    if (toggleFormModal) {
      toggleFormModal();
    }
  };

  const _toggleModal = () => {
    if (toggleShowImage) {
      toggleShowImage();
    }
  };

  const _onDeleteImage = () => {
    if (onDeleteImage) {
      onDeleteImage();
    }
  };

  return _.flowRight(
    connectDragSource,
    connectDropTarget
  )(
    <div>
      <Box
        key={generateShortId()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '16px',
          padding: '8px 0px',
          height: '80px',
          border: (theme) => `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '8px',
          backgroundColor: (theme) => theme.palette.white.paper,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            maxWidth: '840px',
          }}
        >
          <DragIndicatorIcon
            sx={{
              cursor: 'move',
            }}
            color="secondary"
          />
          <Box
            sx={{ borderRadius: '8px', cursor: 'zoom-in' }}
            onClick={(e) => {
              e.stopPropagation();
              _setImage(image);
              _toggleModal();
            }}
          >
            <Image
              src={image?.uri || image?.url}
              alt="car_image"
              fill
              objectFit="contain"
              quality="100"
              wrapperStyle={{ width: '107px' }}
            />
          </Box>
          <Text
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '12px',
              lineHeight: '20px',
              color: (theme) => theme.palette.darkGray.main,
              fontStyle: 'italic',
              maxWidth: '270px',
            }}
          >
            {image?.file?.name || image?.url}
          </Text>
          <Text
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '12px',
              lineHeight: '20px',
              minWidth: '140px',
              color: (theme) => theme.palette.darkGray.main,
            }}
          >
            {`Uploaded At: ${
              image?.updatedAt
                ? formatDate(image?.updatedAt, 'DD-MM-YYYY')
                : '-'
            }`}
          </Text>
          <Text
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '12px',
              lineHeight: '20px',
              color: (theme) => theme.palette.darkGray.main,
            }}
          >
            {`Edited At: ${
              image?.createdAt
                ? formatDate(image?.createdAt, 'DD-MM-YYYY')
                : '-'
            }`}
          </Text>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PermissionsGate>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                height: 0,
                marginTop: '-10px',
              }}
            >
              <IconButton
                disabled={loading}
                onClick={(e) => {
                  e.stopPropagation();
                  _setImage(image);
                  _toggleFormModal();
                }}
                sx={{ cursor: 'pointer' }}
              >
                <EditIcon color="darkGray" fontSize="small" />
              </IconButton>
            </Box>
          </PermissionsGate>
          <PermissionsGate>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                height: 0,
                marginTop: '-10px',
              }}
            >
              <IconButton
                sx={{
                  zIndex: 100,
                }}
                disabled={loading}
                onClick={_onDeleteImage}
              >
                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.white.paper,
                    borderRadius: '4px',
                    height: '24px',
                    width: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    src="/images/delete.svg"
                    alt="car_image"
                    fill
                    objectFit="contain"
                    quality="100"
                    wrapperStyle={{
                      width: '14px',
                      height: '16px',
                    }}
                  />
                </Box>
              </IconButton>
            </Box>
          </PermissionsGate>
        </Box>
      </Box>
    </div>
  );
};

const DraggableImage = _.flowRight([
  DropTarget(
    'ImageItem',
    {
      drop(props, monitor) {
        const { index } = props;
        const draggingItem = monitor.getItem();
        if (draggingItem.id !== props.id) {
          props.onMoveImage(draggingItem.id, index);
        }
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    })
  ),
  DragSource(
    'ImageItem',
    {
      beginDrag(props) {
        return { id: props.image?.id };
      },

      isDragging(props, monitor) {
        return props.id === monitor.getItem().id;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  ),
])(ImageItem);

export default DraggableImage;
