import { pick, omit } from 'lodash';
import { FILTER_RULE_ENUMS, FILTER_OPERATOR_ENUMS } from '@constants';
import { AVAILABILITY_LIMIT } from '@store';

export const getAvailabilityQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = AVAILABILITY_LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  return pick(payload, ['page', 'pageSize', 'order', 'orderBy']);
};

// for https://linear.app/instacar/issue/INS-1996/[instafleet]-availability-improvementsfixes
// returns the rule for cars with:
// a. "Standard" or "Closed Contract" subscription type (status = ΟnLease || OnLeaseARM || ForReturn) AND
// b. Subscription status = Active (Reservation != predel/temp)

export const getAvailabilityRequestPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'searchTerm']) || {};
  const numberFields = [];
  const multyFilterStringFields = [
    'location',
    'brand',
    'model',
    'bodyType',
    'skuType',
    'color',
    'transmission',
    'fuelType',
    'engineType',
  ];
  const multyFilterNumberFields = [
    'status',
    'reservationType',
    'procurementStageId',
  ];

  const { searchTerm } = payload;

  const _getValue = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return numberFields.includes(field)
      ? Number(payload[field])
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  value.push(...(modalFiltersRules?.value || []));

  if (searchTerm) {
    const filedsToSearch = [
      'vehicleInternalId',
      'plate',
      'location',
      'skuId',
      'edition',
    ];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
