import { formatAmount, formatDate, timeFromNow } from '@utils';
import { pickBy, identity } from 'lodash';

export const handleUsers = (data) => {
  if (!data) {
    return [];
  }
  const users = data?.map((item) => {
    return {
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      docs: item.docs || '-',
      email: item.email || '-',
      firstName: item.firstName || '-',
      gateway: item.gateway || '-',
      lastName: item.lastName || '-',
      phoneNumber: item.phoneNumber || '-',
      internalId: item?.internalId,
      id: item.id,
    };
  });
  return users;
};

export const handleUser = (data) => {
  data.details = {
    id: data?.details?.id || '-',
    internalId: data?.details?.internalId || '-',
    email: data?.details?.email || '-',
    createdAt: data?.details?.createdAt
      ? formatDate(data?.details?.createdAt, 'DD/MM/YYYY')
      : '-',
    updatedAt: data?.details?.updatedAt
      ? formatDate(data?.details?.updatedAt, 'DD/MM/YYYY')
      : '-',
    driversLicenseIssueDate: data?.details?.driversLicenseIssueDate
      ? formatDate(data?.details?.driversLicenseIssueDate, 'DD/MM/YYYY')
      : '-',
    dateOfBirth: data?.details?.dateOfBirth
      ? formatDate(data?.details?.dateOfBirth, 'DD/MM/YYYY')
      : '-',
    driversLicenseNumber: data?.details?.driversLicenseNumber || '-',
    rawDriversLicenseIssueDate: data?.details?.driversLicenseIssueDate,
    rawDateOfBirth: data?.details?.dateOfBirth,
    rawDriversLicenseNumber: data?.details?.driversLicenseNumber,
    firstName: data?.details?.firstName || '',
    lastName: data?.details?.lastName || '',
    phoneNumber: data?.details?.phoneNumber || '',
    phoneNumbers: data?.details?.phoneNumbers || [],
    emails: data?.details?.emails || [],
    lang: data?.details?.lang || '',
    gateway: data?.details?.gateway || '',
    driverDocuments:
      data?.details?.docs
        ?.filter(
          (doc) =>
            doc.type === 'DriverLicence' ||
            doc.type === 'PersonalIdentification'
        )
        .map((doc) => {
          const document = { ...doc };
          document.fileName = '-';
          return {
            ...document,
            uploadedAt: formatDate(document?.createdAt, 'DD/MM/YYYY HH:mm:ss'),
          };
        }) || [],
    financialDocuments:
      data?.details?.docs
        ?.filter((doc) => doc.type !== 'DriverLicence')
        ?.filter((doc) => doc.type !== 'PersonalIdentification')
        .map((doc) => {
          const document = { ...doc };
          document.fileName = '-';
          return {
            ...document,
            uploadedAt: formatDate(document?.createdAt, 'DD/MM/YYYY HH:mm:ss'),
          };
        }) || [],
  };
  data.bookings = data?.bookings?.map((booking) => {
    return {
      id: booking?.id,
      created_at: booking?.created_at
        ? formatDate(booking?.created_at, 'DD/MM/YYYY')
        : '-',
      timeAgo: booking?.created_at ? timeFromNow(booking?.created_at) : '-',
      email: booking?.user?.email || '-',
      pipeDriveId: booking?.pipedrive?.id || '-',
      pipeDriveUrl: booking?.pipedrive?.url || '',
      is_active: booking?.is_active,
      region: booking?.region || '-',
      contract: booking?.contract_details?.contract || '-',
      contractPeriod: booking?.contract_details?.contract_period || '-',
      phoneNumber: booking?.user?.phone_number || '-',
      vehicle: booking?.vehicle?.title || '-',
      skuId: booking?.vehicle?.id,
      origin: booking?.origin,
      fee: formatAmount(booking?.final_values?.fee),
      initialFee: formatAmount(booking?.sku_values?.fee),
    };
  });

  data.subscriptions = data.subscriptions?.map((subscription) => {
    return {
      id: subscription?.id,
      type: subscription?.type || '-',
      telephone: subscription?.billingInfo?.telephoneNumber || '-',
      vat: subscription?.billingInfo?.vatRegistrationNumber || '-',
      address: `${subscription?.billingInfo?.address?.streetAddress || ''} ${
        subscription?.billingInfo?.address?.streetNumber || ''
      } ${subscription?.billingInfo?.address?.locality || ''}`,
      active: subscription?.isActive ? 'YES' : 'NO',
    };
  });

  data.creditScore = {
    b2c: {
      deposit: {
        ...data?.creditScore?.b2c?.deposit,
        total: data?.creditScore?.b2c?.deposit?.total || '-',
      },
      standingOrder: {
        ...data?.creditScore?.b2c?.standingOrder,
        total: data?.creditScore?.b2c?.standingOrder?.total || '-',
      },
    },
    b2b: {
      deposit: {
        ...data?.creditScore?.b2b?.deposit,
        total: data?.creditScore?.b2b?.deposit?.total || '-',
      },
      standingOrder: {
        ...data?.creditScore?.b2b?.standingOrder,
        total: data?.creditScore?.b2b?.standingOrder?.total || '-',
      },
    },
  };
  return data;
};

export const handleUserWithSubs = (response) => {
  const data = { ...response };
  const user = data?.user;
  const featureFlags = data?.featureFlags;

  data.details = {
    createdAt: {
      label: 'Created At:',
      renderedValue: data?.createdAt
        ? formatDate(data.createdAt, 'DD/MM/YYYY')
        : '-',
      onEditField: null,
    },
    updatedAt: {
      label: 'Updated At:',
      renderedValue: data?.updatedAt
        ? formatDate(data.updatedAt, 'DD/MM/YYYY')
        : '-',
      onEditField: null,
    },
    email: {
      label: 'E-mail:',
      renderedValue: data?.email || '-',
      onEditField: null,
    },
    telephoneNumber: {
      canEdited: true,
      label: 'Mobile Phone:',
      type: 'text',
      renderedValue: user?.phoneNumber || '-',
      defaultValue: user?.phoneNumber,
      instructions:
        'Telephone number should be in E.164 format e.g. +3069xxxxxxxx',
      onEditField: null,
      fieldWrapperStyle: {
        width: '100%',
      },
    },
  };
  data.billingInfoRaw = data.billingInfo;
  // TODO: this should be in a selector
  data.billingInfo = data.billingInfo?.reduce((acc, cur) => {
    const normalizedBillingItem = pickBy(
      {
        id: cur.billingId,
        firstName: {
          label: 'First Name:',
          renderedValue: cur.firstName || '-',
          defaultValue: cur.firstName,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        lastName: {
          label: 'Last Name:',
          renderedValue: cur.lastName || '-',
          defaultValue: cur.lastName,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        vat: {
          label: 'Tax code:',
          renderedValue: cur.vat || '-',
          defaultValue: cur.vat,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
          // TODO: enable after permission handle
          // disabled: !!cur.vat,
        },
        companyName: cur.companyName
          ? {
              label: 'Business Name:',
              renderedValue: cur.companyName || '-',
              defaultValue: cur.companyName,
              onEditField: null,
              canEdited: true,
              type: 'text',
              placeholder: '',
              rules: {
                required: '* This field is required',
              },
              fieldWrapperStyle: {
                width: '100%',
              },
              hidden: false,
            }
          : undefined,
        doi: {
          label: 'D.O.Y:',
          renderedValue: cur.doi || '-',
          defaultValue: cur.doi,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        activity: {
          label: 'Activity:',
          renderedValue: cur.activity || 'Ιδιώτης',
          defaultValue: cur.activity || 'Ιδιώτης',
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        phoneNumber: {
          label: 'Phone Number:',
          renderedValue: cur.phoneNumber || '-',
          defaultValue: cur.phoneNumber,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        address: {
          label: 'Street Address:',
          renderedValue: cur.address || '-',
          defaultValue: cur.address,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        email: {
          label: 'E-mail:',
          renderedValue: cur.email || '-',
          defaultValue: cur.email,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'invalid email address',
            },
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        postalCode: {
          label: 'Postal Code:',
          renderedValue: cur.postalCode || '-',
          defaultValue: cur.postalCode,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
      },
      identity
    );
    acc.push(normalizedBillingItem);
    return acc;
  }, []);

  data.featureFlags = {
    isUserAppEnabled: {
      label: 'IS USER APP ENABLED',
      renderedValue: featureFlags?.isUserAppEnabled,
      hasSwitch: true,
    },
  };

  return data;
};

export const handleUserSubscriptions = (response, subscriberId) => {
  const data = [...response];
  data?.map((subscription) => {
    const billingInfo =
      subscription?.subscribers[0]?.subscriptions[0]?.billingInfo;
    subscription.subscriptionType = subscription?.type;

    subscription.type = subscription?.subscribers[0]?.subscriptions[0]?.type;

    subscription.phoneField = {
      telephoneNumber: {
        label: 'Telephone Number',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.telephoneNumber,
        instructions:
          'Telephone number should be in E.164 format e.g. +3069xxxxxxxx',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    };
    subscription.vatField = {
      vat: {
        label: 'VAT number',
        type: 'number',
        placeholder: '',
        defaultValue: billingInfo?.vatRegistrationNumber,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    };
    subscription.addressField = {
      streetAddress: {
        label: 'Street Name',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.streetAddress || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      streetNumber: {
        label: 'Street Number',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.streetNumber || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      locality: {
        label: 'Locality',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.locality || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      postalCode: {
        label: 'Postal Code',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.postalCode || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      region: {
        label: 'Region',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.region || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      country: {
        label: 'Country',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.country || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
    };
    subscription.plate = subscription?.vehicle?.plate;
    subscription.amount = formatAmount(subscription?.rules[0]?.amount?.value);

    const currentSubscriber = subscription?.subscribers.find(
      (subscriber) => subscriber.id === subscriberId
    );

    const currentSubscription = currentSubscriber?.subscriptions.find(
      (subscr) => subscr.id === subscription.id
    );
    subscription.role = currentSubscription?.role === 1 ? 'OWNER' : 'DRIVER';
    return subscription;
  });
  return data;
};
