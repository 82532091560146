/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getDriversePartner, selectDriversePartnersState } from '@store';
import { showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { handleDriverseCampaigns } from 'store/driverseCampaigns/driverseCampaigns.handlers';
import { driverseServices } from '@services';
import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';

export const PARTNERS_CAMPAIGNS_PAGE_SIZE = 1000;

export const useDriversePartnerOperations = (firstMount) => {
  const { loading, partner, errorMessage, hasErrors } = useSelector(
    selectDriversePartnersState
  );
  const {
    back,
    query: { partnerId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getDriversePartner = useCallback(async () => {
    if (partnerId) {
      await dispatch(getDriversePartner(partnerId));
    }
  }, [partnerId, dispatch]);

  const campaigns = useQuery(
    [QUERY_KEYS.GET_DRIVERSE_CAMPAIGNS_BY_PARTNER_ID_KEY, partnerId],
    () =>
      driverseServices?.getDriverseCampaigns({
        params: {
          page: 1,
          pageSize: PARTNERS_CAMPAIGNS_PAGE_SIZE,
        },
        payload: {
          field: 'memberId',
          operator: FILTER_OPERATOR_ENUMS['='],
          type: FILTER_RULE_ENUMS.CompareStringRuleType,
          value: partnerId,
        },
      }),
    {
      enabled: !!partnerId,
    }
  );

  useEffect(() => {
    if (partnerId && firstMount) {
      _getDriversePartner();
    }
  }, [partnerId, _getDriversePartner]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    partnerId,
    partner,
    campaigns: handleDriverseCampaigns(campaigns?.data?.data),
    hasErrors,
    errorMessage,
    campaignsTotal: campaigns?.data?.meta?.total || 0,
    getDriversePartner: _getDriversePartner,
    getDriversePartnersCampaigns: campaigns?.refetch,
    goBack: back,
  };
};
