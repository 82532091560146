import { SUBSCRIPTIONS_TYPES } from '@constants';
import { formatDate, groupItems } from '@utils';

export const handleInspectionedCars = (array, groupType) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    let subscriberName = '';
    if (item?.plate) {
      newArray[index] = item;
      return;
    }
    item?.subscribers?.forEach((sub) => {
      sub?.subscriptions?.forEach((subsciption) => {
        if (subsciption?.role === 1) {
          subscriberName = sub?.firstName
            ? `${sub?.firstName} ${sub?.lastName}`
            : '-';
        }
      });
    });

    const row = {
      plate: item?.vehicle?.plate || '-',
      subscriber: subscriberName,
      image: item?.vehicle?.images?.[0]?.url,
      deliveryDate: item.deliveryDate
        ? formatDate(item.deliveryDate, 'DD.MM.YYYY')
        : '-',
      inspectionTime: item.inspectionDate
        ? formatDate(item.inspectionDate, 'HH:mm')
        : '-',
      rawDeliveryDate: item.deliveryDate,
      hasDeliveryTenancyAgreement: !!item?.deliveryInfo?.tenancyAgreement,
      returnDate: item.returnDate
        ? formatDate(item.returnDate, 'DD.MM.YYYY')
        : '-',
      rawReturnDate: item.returnDate,
      hasReturnTenancyAgreement: !!item?.returnInfo?.tenancyAgreement,
      inspectionDate: item?.inspectionDate
        ? formatDate(item?.inspectionDate, 'DD.MM.YYYY')
        : '-',
      type: item?.returnInspectionInfo?.date ? 'return' : 'delivery',
      assignee: item?.returnInspectionInfo?.date
        ? item?.returnInspectionInfo?.assigneeName || '-'
        : item?.deliveryInspectionInfo?.assigneeName || '-',
      isInspected: item.returnInspectionInfo
        ? item.returnInspectionInfo.assigneeId &&
          item.deliveryInspectionInfo?.assigneeId
        : item.deliveryInspectionInfo && item.deliveryInspectionInfo.assigneeId,
      // eslint-disable-next-line no-nested-ternary
      province: item?.returnInspectionInfo?.date
        ? item?.returnInfo?.province
          ? 'Province'
          : ''
        : item?.deliveryInfo?.province
        ? 'Province'
        : '',
      location: item?.returnInspectionInfo?.date
        ? item?.returnInspectionInfo?.point
        : item?.deliveryInspectionInfo?.point,
      id: item.id,
      subscriptionType: SUBSCRIPTIONS_TYPES[item.type],
    };
    newArray[index] = row;

    return newArray;
  });
  const groupTypeByDate = groupItems(array, groupType, 'desc');
  return groupTypeByDate;
};
