/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { driverseServices } from '@services';
import {
  FILTER_OPERATOR_ENUMS,
  FILTER_RULE_ENUMS,
  GENERIC_ERROR_MESSAGE,
  LIMIT,
} from '@constants';
import { asyncForEach } from '@utils';
import {
  getDriverseCampaignsRequestQueryParams,
  getDriverseCampaignsRequestPayload,
} from 'helpers';
import {
  handleDriverseCampaigns,
  handleDriverseMembers,
} from './driverseCampaigns.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  data: [],
  total: 0,
  pageSize: LIMIT,
  filters: {
    urlParams: undefined,
  },
};

// Actual Slice
export const driverseCampaignsSlice = createSlice({
  name: 'driverseCampaigns',
  initialState,
  reducers: {
    fetchDriverseCampaigns: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.data = [];
    },
    fetchDriverseCampaignsSuccess: (state, { payload }) => {
      state.data = handleDriverseCampaigns(
        payload?.response?.data,
        payload?.members?.data
      );
      state.members = handleDriverseMembers(payload?.members?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.total = payload?.response?.meta?.total || 0;
      state.filters.urlParams = payload.urlParams;
    },
    fetchDriverseCampaignsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchDriverseCampaigns,
  fetchDriverseCampaignsSuccess,
  fetchDriverseCampaignsFailure,
} = driverseCampaignsSlice.actions;

export const getDriverseCampaigns = ({ urlParams = {} }) => {
  return async (dispatch) => {
    dispatch(fetchDriverseCampaigns());
    try {
      const params = getDriverseCampaignsRequestQueryParams(urlParams);
      const payload = getDriverseCampaignsRequestPayload(urlParams);

      const response = await driverseServices?.getDriverseCampaigns({
        params,
        payload,
      });
      await asyncForEach(response?.data, async (item, index, array) => {
        const { data } = await driverseServices.getDriverseCoupons({
          params: { page: 1, pageSize: 1000 },
          payload: {
            field: 'campaignId',
            operator: FILTER_OPERATOR_ENUMS['='],
            type: FILTER_RULE_ENUMS.CompareStringRuleType,
            value: item?.id,
          },
        });
        array[index].remainingCoupons = data?.filter(
          (coupon) => !coupon?.isUsed
        )?.length;
      });
      response.meta.page = urlParams.page || 1;
      const members = await driverseServices?.getDriverseMembers({
        params: { page: 1, pageSize: 1000 },
        payload: {
          type: FILTER_RULE_ENUMS.EmptyRuleType,
        },
      });

      dispatch(
        fetchDriverseCampaignsSuccess({
          response,
          urlParams,
          members,
        })
      );
    } catch (error) {
      dispatch(fetchDriverseCampaignsFailure(error));
    }
  };
};

export const selectDriverseCampaignsTableState = (state) =>
  state.driverseCampaigns;

export default driverseCampaignsSlice.reducer;
