/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  selectDriverseCampaignsTableState,
  getDriverseCampaigns,
} from '@store';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';

export const useDriverseCampaignsOperations = (fetchOnMount) => {
  const {
    loading,
    data,
    pageSize,
    total,
    errorMessage,
    filters,
    hasErrors,
    members,
  } = useSelector(selectDriverseCampaignsTableState);

  const dispatch = useDispatch();

  const { push, query, isReady } = useRouter();
  const { page = '1', orderBy = '', order = '', ...remainingQueries } = query;

  const remainingQueriesKeys = Object.keys(remainingQueries);

  const _getDriverseCampaigns = useCallback(
    async (urlParams, modalFiltersRules) => {
      await dispatch(
        getDriverseCampaigns({
          urlParams: getCleanedUrlParams(urlParams),
          modalFiltersRules,
        })
      );
    },
    [page, orderBy, order, dispatch, ...remainingQueriesKeys]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      const params = {
        orderBy,
        order,
        page: currentPage,
        ...remainingQueries,
      };
      const url = getUrl(params, URLS.driverseCampaigns);

      push(url, undefined, { shallow: true });
    },
    [push]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      const params = {
        ...sortingModel,
        page,
        ...remainingQueries,
      };

      const url = getUrl(params, URLS.driverseCampaigns);

      push(url, undefined, { shallow: true });
    },
    [push, page, orderBy, order]
  );

  const _initialize = async () => {
    await _getDriverseCampaigns(query, filters.modalFiltersRules);
  };

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _initialize();
    }
  }, [isReady, query]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    total,
    data,
    members,
    hasErrors,
    errorMessage,
    page,
    pageSize,
    getDriverseCampaigns: _getDriverseCampaigns,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
