const sortArray = (array, sortBy = 'text', order = 'asc') => {
  return array.sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    return order === 'asc' ? aValue - bValue : bValue - aValue;
  });
};

export default sortArray;
