import { BOARDS_ID } from '@constants';
import { formatDate } from '@utils';

export const handleDefleetData = (data) => {
  if (!data.length) {
    return {};
  }

  const tasks = data.map((item) => {
    return {
      ...item,
      href: `/defleet/${item?.id}`,
      plate: item.customFields?.vehiclePlate || '',
      status: item?.status,
      description: item?.customFields?.vehicleDescription || '-',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      id: item?.id,
      servicePoint: item?.customFields?.servicePoint || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
  });

  return {
    boardId: BOARDS_ID.DEFLEET,
    tasks,
  };
};
