import { formatDate, generateShortId } from '@utils';

export const handleServiceVehiclesData = (data) => {
  if (!('serviceVehicles' in data)) {
    return [];
  }
  const array = data?.serviceVehicles?.data;
  const types = data?.serviceVehiclesTypes;
  array?.forEach((item, index, newArray) => {
    const row = {
      status: item.status || '-',
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      startedAt: item.startedAt
        ? formatDate(item.startedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      completedAt: item.completedAt
        ? formatDate(item.completedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      type: types?.[item?.type] || '-',
      subscriber: item?.subscriptionOwnerName || '-',
      vehicleDescription: item?.vehicleName || '-',
      plate: item?.vehiclePlate || '-',
      requestPerson: item?.requestedBy || '-',
      assignee: item?.assignee || '-',
      title: `${item?.vehicle?.sku?.brand || ''} - ${
        item?.vehicle?.sku?.model || ''
      }`,
      description: `${
        item.startedAt ? formatDate(item.startedAt, 'DD/MM/YYYY HH:mm') : '-'
      } - ${
        item.completedAt
          ? formatDate(item.completedAt, 'DD/MM/YYYY HH:mm')
          : '-'
      }`,
      href: `/serviceVehicles/${item?.id}`,
      id: item.id,
    };
    newArray[index] = row;

    return newArray;
  });
  return {
    services: array,
    columns: [
      {
        id: 0,
        title: 'TODO',
        cardIds: array?.map((item) => item?.id),
      },
      {
        id: 1,
        title: 'Doing',
        cardIds: [],
      },
      {
        id: 2,
        title: 'Done',
        cardIds: [],
      },
    ],
    requestTypes: { '': '-', ...types },
  };
};

export const handleServiceVehicleData = (data, types) => {
  if (!data) {
    return [];
  }
  const fields =
    data.fields?.map((field) => {
      const newField = { ...field };
      if (newField?.type === 'slots') {
        newField.value = newField?.value?.map((value) => {
          return {
            ...value,
            slots: value?.slots?.map((slot) => {
              return { ...slot, vendor: value?.vendor, id: generateShortId() };
            }),
          };
        });
      }
      return newField;
    }) || [];
  const actions = data?.actions?.map((action) => {
    return {
      ...action,
      createdAt: action.createdAt
        ? formatDate(action.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
    };
  });

  const slots = data?.fields?.filter((field) => {
    return field?.type === 'slots';
  });
  let slot;
  if (slots?.length) {
    slot = slots[0]?.value
      ?.filter((sl) => {
        return sl?.isSelected;
      })
      ?.pop();
  }

  if (data?.images?.length) {
    fields?.push({
      key: 'accidentPhotos',
      type: 'files',
      value: data?.images,
    });
  }

  const serviceVehicle = {
    ...data,
    status: data.status || '-',
    subscriptionOwnerName: data?.subscriptionOwnerName || '-',
    subscriptionOwnerId: data?.subscriber?.id || '-',
    subscriptionDriver: data?.requestedBy || '-',
    requestType: types[data?.type],
    servicePoint: slot ? slot?.vendor : '',
    odometer: `${data?.vehicle?.mileageFromOdometer?.value} ${data?.vehicle?.mileageFromOdometer?.unitCode}`,
    createdAt: data.createdAt
      ? formatDate(data.createdAt, 'DD/MM/YYYY HH:mm')
      : '-',
    updatedAt: data.updatedAt
      ? formatDate(data.updatedAt, 'DD/MM/YYYY HH:mm')
      : '-',
    startedAt: data.startedAt
      ? formatDate(data.startedAt, 'DD/MM/YYYY HH:mm')
      : '-',
    completedAt: data.completedAt
      ? formatDate(data.completedAt, 'DD/MM/YYYY HH:mm')
      : '-',
    type: types?.[data?.type] || '-',
    assignee: data?.assignee || '-',
    vehicleName: data?.vehicleName || '-',
    vehicleId: data?.vehicle?.id || '-',
    internalId: data?.vehicle?.internalId || '-',
    plate: data?.vehiclePlate || '-',
    fields,
    actions,
  };
  return serviceVehicle;
};

export const handleAgentsData = (data = []) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => ({
    status: item.status || '-',
    createdAt: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : '-',
    updatedAt: item.updatedAt ? formatDate(item.updatedAt, 'DD/MM/YYYY') : '-',
    email: item?.email || '-',
    firstName: item?.firstName || '-',
    lastName: item?.lastName || '-',
    fullName: `${item?.firstName || ''} ${item?.lastName || ''}`,
    role: item?.role || '-',
    id: item.id,
    userName: item.username || '-',
    isActive: item?.isActive,
    statusIsActive: item?.isActive ? 'Active' : 'Inactive',
  }));
};
