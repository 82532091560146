import { request } from '@utils';

const getEmails = async ({ params, payload }) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/emails/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEmailById = async (emailId) => {
  const results = await request.get(`/v1/emails/${emailId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editEmailById = async (emailId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/emails/${emailId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editEmails = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/emails/bulk`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEmailHistoryById = async (id) => {
  const results = await request.get(
    `/v1/changelogs?entityType=email&entityID=${id}&orderBy=createdAt&order=desc`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getAttachment = async (id, attachmentId) => {
  const results = await request.get(
    `/v1/emails/${id}/attachment/${attachmentId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const syncEmails = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/emails/sync`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEmailBoxes = async (params, payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/mailboxes/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addEmailBox = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/mailboxes`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getOAuthMailboxURL = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/mailboxes/generateOAuthURL`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const validateEmailBox = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/mailboxes/validateMailbox
`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const replyEmail = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/emails/reply`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getEmails,
  getAttachment,
  getEmailById,
  editEmailById,
  getEmailHistoryById,
  editEmails,
  syncEmails,
  getEmailBoxes,
  addEmailBox,
  getOAuthMailboxURL,
  validateEmailBox,
  replyEmail,
};
