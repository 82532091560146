import { generateShortId } from '@utils';
import { ENTITIES_PERMISIONS_NAMES } from 'components/common/PermissionsGate/constants';

export const handlePermissionsData = (data) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => ({
    id: item?.id || generateShortId(),
    role: item.role || '-',
    permission: ENTITIES_PERMISIONS_NAMES[item?.permission] || '-',
  }));
};
