import theme from 'config/theme';

export const SELL_REQUESTS_TASK_STATUSES_NAMES = {
  55: {
    label: 'Pre Leads',
    styles: {
      backgroundColor: theme.palette.grays.gray300,
      color: theme.palette.grays.gray900,
    },
  },
  56: {
    label: 'Leads',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
    },
  },
  57: {
    label: 'Tel Contact 1',
    styles: {
      backgroundColor: theme.palette.grays.gray200,
      color: theme.palette.grays.gray600,
    },
  },
  58: {
    label: 'No Answer',
    styles: {
      backgroundColor: theme.palette.violets.violet200,
      color: theme.palette.violets.violet900,
    },
  },
  59: {
    label: 'Tel Contact 2',
    styles: {
      backgroundColor: theme.palette.grays.gray400,
      color: theme.palette.grays.gray900,
    },
  },
  60: {
    label: 'Follow Up',
    styles: {
      backgroundColor: theme.palette.purples.purple200,
      color: theme.palette.purples.purple900,
    },
  },
  61: {
    label: 'Finance Approval',
    styles: {
      backgroundColor: theme.palette.limes.lime200,
      color: theme.palette.limes.lime900,
    },
  },
  62: {
    label: 'Book Appointment',
    styles: {
      backgroundColor: theme.palette.pinks.pink200,
      color: theme.palette.pinks.pink900,
    },
  },
  7: {
    label: 'Loans',
    styles: {
      backgroundColor: theme.palette.blues.babyBlue200,
      color: theme.palette.blues.babyBlue900,
    },
  },
  8: {
    label: 'Buyout Deposit',
    styles: {
      backgroundColor: theme.palette.blues.blue100,
      color: theme.palette.blues.blue900,
    },
  },
  9: {
    label: 'Vehicle Sales',
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  10: {
    label: 'Transferred',
    styles: {
      backgroundColor: theme.palette.blues.primaryBlue300,
      color: theme.palette.blues.blue900,
    },
  },
  63: {
    label: 'Partner Vehicles',
    styles: {
      backgroundColor: theme.palette.greens.green400,
      color: theme.palette.greens.green900,
    },
  },
  29: {
    label: 'For Delivery',
    styles: {
      backgroundColor: theme.palette.teals.teal200,
      color: theme.palette.teals.teal900,
    },
  },
  3: {
    label: 'Done',
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.green900,
    },
  },
  11: {
    label: 'Customer Rejected',
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
    },
  },
  12: {
    label: 'Cancelled',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
};

export const SELL_REQUESTS_TASK_STATUSES_SERVER_NAMES = {
  55: 'PreLeads',
  56: 'HotLeads',
  57: 'TelContact1',
  58: 'NoAnswer',
  59: 'TelContact2',
  60: 'FollowUp',
  61: 'FinanceApproval',
  62: 'BookAppointment',
  7: 'Loans',
  8: 'BuyoutDeposit',
  9: 'VehicleSales',
  10: 'Transferred',
  30: 'SaleCompleted',
  29: 'ForDelivery',
  3: 'Done',
  11: 'CustomerRejected',
  12: 'Cancelled',
};

export const SELL_VEHICLES_ACTION = {
  sendVehicleSalesOfferEmail: 0,
};

export const SELL_REQUESTS_STATUSES_NAMES_MAP = {
  PreLeads: 55,
  HotLeads: 56,
  TelContact1: 57,
  NoAnswer: 58,
  TelContact2: 59,
  FollowUp: 60,
  FinanceApproval: 61,
  BookAppointment: 62,
  Loans: 7,
  BuyoutDeposit: 8,
  VehicleSales: 9,
  Transferred: 10,
  SaleCompleted: 30,
  ForDelivery: 29,
  Done: 3,
  CustomerRejected: 11,
  Cancelled: 12,
};

export const SELL_REQUESTS_LABEL_FIELD_NAMES = {
  2: '',
};

export const SELL_REQUESTS_TASK_FIELD_NAMES = {
  name: 'Title',
  subscriberName: 'Subscriber Name',
  subscriberSurname: 'Subscriber Surname',
  promisedReturnDate: 'Promised Return Date',
  plannedUserReturnDate: 'Planned User Return Date',
  dateAndTime: 'Date & Time',
  userId: 'User ID',
  carId: 'Car ID',
  pipedriveId: 'Pipedrive ID',
  trelloBillingCardId: 'Trello Billing Card ID',
  vehicleModel: 'Vehicle Model',
  vehicleBrand: 'Vehicle Brand',
  trelloDriversCardId: 'Trello Drivers Card ID',
  time: 'Time of Return',
  trelloOpsCardId: 'Τrello Ops Card ID',
  location: 'Location of Return',
  plate: 'Plate',
  label: 'Tags',
  status: 'Status',
  tags: 'Tag',
  returnedDate: 'Return Date',
  billingComments: 'Billing Comments',
  billingChargeType: 'Charge',
  approvalStatus: 'Approval Status',
  bank: 'Bank',
  commercialAssessment: 'Commercial Assessment',
  comparablePrice: 'Comparable Price',
  customerRejectedReason: 'Customer Rejected Reason',
  dateOfDeposit: 'Date of Deposit',
  depositConfirmation: 'Deposit Confirmation',
  liftingOfWithholding: 'Lifting of Withholding',
  downpayment: 'Downpayment',
  extraCap: 'Extra Cap',
  finalPrice: 'Final Price',
  financedAmount: 'Financed Amount',
  certificateOfUnpaidTrafficFees: 'Certificate of Unpaid Traffic Fees',
  invoice: 'Invoice',
  kteo: 'KTEO',
  purchaseInvoice: 'Purchase Invoice',
  vehicleTransferAuthorization: 'Vehicle Transfer Authorization',
  loan: 'Loan',
  loanRequestStatus: 'Loan Request Status',
  managingCosts: 'Managing Costs',
  marginPercentage: 'Margin Percentage',
  minimumPrice: 'Minimum Price',
  nbv: 'NBV',
  offerExpirationDate: 'Offer Expiration Date',
  offerRequestDate: 'Offer Request Date',
  offerSentDate: 'Offer Sent Date',
  payoutClientApproval: 'Payout Client Approval',
  payoutMonthlyInstallment: 'Payout Monthly Installment',
  payoutPlanMonths: 'Payout Plan Months',
  reasonsOfRejection: 'Reasons of Rejection',
  disableSecurity: 'Disable Security',
  scheduleDeliveryOfLicenseAndSecondKey:
    'Schedule Delivery of License and Second Key',
  vehicleDefleeted: 'Vehicle Defleeted',
  transferOfOwnershipFee: 'Transfer of Ownership Fee',
  finalPriceRejectionReason: 'Rejection Reason',
  administrativeExpenses: 'Administrative Expenses',
  interestRate: 'Interest Rate (%)',
  loanAssignee: 'Loan Assignee',
  loanContractId: 'Loan Contract ID',
  installments: 'Installments',
  months: 'Months',
  amount: 'Amount',
  selected: 'Selected',
  copyOfLicenseSending: 'Copy of License Sending',
  loanDisbursed: 'Loan Disbursed',
  vehiclePurchasePrice: 'Vehicle Purchase Price',
};
