import { toast } from 'react-toastify';
import generateShortId from './generateShortId';

const showInfo = (message, id, duration = 6000) => {
  toast.info(message, {
    toastId: id || generateShortId(),
    autoClose: duration,
  });
};

export default showInfo;
