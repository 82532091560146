/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { cloneDeep } from 'lodash';
import { readFile, generateShortId, showError, formatDate } from '@utils';
import { Button, IconButton, Image, PermissionsGate } from '@components';
import Modal from '@mui/material/Modal';
import { useDropzone } from 'react-dropzone';
import { GENERIC_ERROR_MESSAGE } from '@constants';
// eslint-disable-next-line import/no-named-as-default
import EditARNMDocumentModal from '../UploadARNMDocument/EditARNMDocumentModal';

const UploadARNMImage = forwardRef(
  (
    { onChange, onDeleteImage, images, loading, shouldChangeOnDelete = true },
    ref
  ) => {
    const [localImages, setLocalImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const { getRootProps, getInputProps, acceptedFiles, isDragAccept } =
      useDropzone({
        noClick: false,
        noKeyboard: false,
        multiple: true,
        accept: {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
        },
      });

    useImperativeHandle(ref, () => ({
      _reset() {},
    }));

    const _toggleModal = () => {
      setOpenImage(!openImage);
    };

    const _handleCloseEditModa = () => {
      setOpenEditDialog(!openEditDialog);
    };

    const _handleEditImage = (values, doc) => {
      const newDoc = cloneDeep(doc);
      if (!newDoc?.tags) {
        newDoc.tags = [];
      }
      if (values?.customer) {
        if (!newDoc.tags?.includes('customer')) {
          newDoc.tags?.push('customer');
        }
      } else if (!values?.customer && newDoc.tags?.includes('customer')) {
        newDoc.tags = newDoc.tags?.filter((item) => item !== 'customer');
      }
      if (values?.servicePoint) {
        if (!newDoc.tags?.includes('service-point')) {
          newDoc.tags?.push('service-point');
        }
      } else if (
        !values?.servicePoint &&
        newDoc.tags?.includes('service-point')
      ) {
        newDoc.tags = newDoc.tags?.filter((item) => item !== 'service-point');
      }
      if (values?.insurance) {
        if (!newDoc.tags?.includes('insurance')) {
          newDoc.tags?.push('insurance');
        }
      } else if (!values?.insurance && newDoc.tags?.includes('insurance')) {
        newDoc.tags = newDoc.tags?.filter((item) => item !== 'insurance');
      }
      let newLocalImages = cloneDeep(localImages);
      newLocalImages = newLocalImages?.map((item) => {
        if (item?.id === newDoc?.id) {
          return newDoc;
        }
        return item;
      });
      newDoc.isPatched = true;
      onChange(newLocalImages);
      setLocalImages(newLocalImages);
      _handleCloseEditModa();
    };

    const _getImage = async (imageFiles) => {
      try {
        if (loading) return;
        const fileArray = [...imageFiles];
        const data = await Promise.all(
          fileArray.map(async (file) => {
            const fileContents = await readFile(file);
            fileContents.id = generateShortId();
            fileContents.localFile = true;
            return fileContents;
          })
        );
        setLocalImages([...localImages, ...data]);
        const imagesToupLoad = localImages?.filter((img) => {
          return img?.localFile;
        });
        onChange([...imagesToupLoad, ...data]);
      } catch (error) {
        showError(error || GENERIC_ERROR_MESSAGE);
      }
    };

    useEffect(() => {
      if (images?.length) {
        setLocalImages([...images]);
      }
    }, [images]);

    const _handleCloseDialog = (e) => {
      e?.stopPropagation();
      setOpenDialog(false);
    };

    const _onDeleteImage = async () => {
      try {
        if (!selectedImage.localFile) {
          await onDeleteImage(selectedImage?.id);
        }
        const newImages = localImages?.filter((img) => {
          return selectedImage?.id !== img?.id;
        });
        setLocalImages(newImages);
        if (shouldChangeOnDelete) {
          onChange(newImages);
        }
      } catch (error) {
        showError(error?.message);
      }
    };

    const _onConfirmation = () => {
      if (_onDeleteImage) {
        _onDeleteImage();
      }
      _handleCloseDialog();
    };

    const _computeSize = useCallback((files) => {
      let fileSize = 0;
      files?.forEach((file) => {
        fileSize += file.size;
      });
      return (fileSize / 1024 ** 2).toFixed(2);
    }, []);

    useEffect(() => {
      if (_computeSize(acceptedFiles) <= 10) {
        _getImage(acceptedFiles);
        setSelectedImage(null);
      } else {
        showError('You cannot upload files more than 30MB');
      }
    }, [acceptedFiles]);

    return (
      <>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.white.paper,
            marginTop: '16px',
          }}
        >
          <Box
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.palette.secondary.main}`,
              paddingBottom: '8px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Upload Photos</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '14px',
              marginTop: '16px',
            }}
          >
            <Box
              sx={{
                minWidth: '174px',
                width: '174px',
                height: 'max-content',
              }}
              {...getRootProps()}
            >
              <label htmlFor="raised-button-file">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    marginBottom: 4,
                  }}
                >
                  <input {...getInputProps()} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        maWidth: '292px',
                        height: '182px',
                        border: (theme) =>
                          `${isDragAccept ? 2 : 1}px solid ${
                            isDragAccept
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main
                          }`,
                        borderRadius: '8px',
                        backgroundColor: (theme) =>
                          theme.palette.backgroundGray.paper,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        padding: 1,
                        backgroundImage: "url('/images/file.svg')",
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{
                          width: '100%',
                          maxWidth: '156px',
                          textAlign: 'center',
                          fontSize: '14px',
                        }}
                      >
                        Επιλέξτε τα αρχεία ή σύρε και άφησέ τα εδώ
                      </Typography>
                      <Typography
                        sx={{
                          width: '100%',
                          maxWidth: '156px',
                          textAlign: 'center',
                          fontSize: '14px',
                        }}
                      >
                        Μέγιστο μέγεθος φωτογραφίας 10 Mb
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          width: '48px',
                          height: '48px',
                          position: 'absolute',
                          bottom: '-24px',
                          border: (theme) =>
                            `1px solid ${theme.palette.lightGray.main}`,
                          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
                          borderRadius: '50%',
                          backgroundImage: "url('/images/add.svg')",
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </label>
            </Box>

            <Box
              sx={{
                flex: 1,
              }}
            >
              {localImages?.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  {localImages?.map?.((image) => {
                    return (
                      <Box
                        key={generateShortId()}
                        sx={{
                          padding: '8px',
                          width: '100%',
                          border: (theme) =>
                            `1px solid ${theme.palette.secondary.main}`,
                          borderRadius: '8px',
                          backgroundColor: (theme) => theme.palette.white.paper,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedImage(image);
                              _toggleModal();
                            }}
                            sx={{
                              cursor: 'zoom-in',
                            }}
                          >
                            <Image
                              src={image?.uri || image?.url}
                              alt="car_image"
                              objectFit="contain"
                              quality="100"
                              width={56}
                              height={42}
                              wrapperStyle={{ width: '56px', height: '42px' }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              height: '100%',
                              textAlign: 'center',
                              gap: '8px',
                              color: (theme) => theme.palette.darkGray.main,
                              overflowWrap: 'anywhere',
                            }}
                          >
                            <Tooltip title={image?.id}>
                              <Typography
                                variant="span"
                                sx={{
                                  fontSize: '0.75rem',
                                  lineHeight: '1.25rem',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {`ID: ${image?.id}`}
                              </Typography>
                            </Tooltip>
                            <Tooltip title={image?.name}>
                              <Typography
                                variant="span"
                                sx={{
                                  fontSize: '0.75rem',
                                  lineHeight: '1.25rem',
                                  fontStyle: 'italic',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {image?.filename || image?.file?.name}
                              </Typography>
                            </Tooltip>
                            <Tooltip
                              title={formatDate(image?.createdAt, 'DD/MM/YYYY')}
                            >
                              <Typography
                                variant="span"
                                sx={{
                                  fontSize: '0.75rem',
                                  lineHeight: '1.25rem',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {`Uploaded at: ${formatDate(
                                  image?.createdAt,
                                  'DD/MM/YYYY'
                                )}`}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <PermissionsGate>
                            <Box
                              sx={{
                                display: 'flex',
                              }}
                            >
                              <IconButton
                                disabled={loading}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  _handleCloseEditModa();
                                  setSelectedImage(image);
                                }}
                              >
                                <Box
                                  sx={{
                                    height: '24px',
                                    width: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <EditIcon color="secondary" />
                                </Box>
                              </IconButton>
                              <IconButton
                                type="button"
                                disabled={loading}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenDialog(!openDialog);
                                  setSelectedImage(image);
                                }}
                                sx={{ padding: 0 }}
                              >
                                <Box
                                  sx={{
                                    height: '24px',
                                    width: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Image
                                    src="/images/delete.svg"
                                    alt="car_image"
                                    fill
                                    objectFit="contain"
                                    quality="100"
                                    wrapperStyle={{
                                      width: '14px',
                                      height: '16px',
                                    }}
                                  />
                                </Box>
                              </IconButton>
                            </Box>
                          </PermissionsGate>
                        </Box>
                      </Box>
                    );
                  })}
                  <EditARNMDocumentModal
                    document={selectedImage}
                    isOpen={openEditDialog}
                    onClose={_handleCloseEditModa}
                    onSubmit={(form) => _handleEditImage(form, selectedImage)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Modal open={openImage} onClose={_toggleModal} disableAutoFocus>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '466px',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '4px',
            }}
          >
            <Image
              src={selectedImage?.url || selectedImage?.uri}
              alt="car_image"
              fill
              objectFit="contain"
              quality="100"
              wrapperStyle={{
                width: '100%',
                height: '466px',
              }}
            />
          </Box>
        </Modal>
        <Modal open={openDialog} onClose={_toggleModal} disableAutoFocus>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: '4px',
              borderRadius: '4px',
            }}
          >
            <DialogTitle id="alert-dialog-title">Delete image?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="cancel"
                onClick={_handleCloseDialog}
                loading={loading}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={_onConfirmation}
                autoFocus
                loading={loading}
              >
                Delete
              </Button>
            </DialogActions>
          </Box>
        </Modal>
      </>
    );
  }
);

const memoizedUploadARNMImage = React.memo(UploadARNMImage);
export { memoizedUploadARNMImage as UploadARNMImage };
export default memoizedUploadARNMImage;
