import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { LIMIT } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { usersServices } from '@services';
import { handleUsers } from './utils';

export const useUsersOperations = () => {
  const {
    push,
    query: { page = 1, pageSize = LIMIT, orderBy = '', order = '' },
    isReady,
  } = useRouter();

  const usersResponse = useQuery(
    [QUERY_KEYS.GET_USERS_KEY, { page, pageSize, orderBy, order }],
    () =>
      usersServices?.getUsers({
        page,
        pageSize,
        orderBy,
        order,
      }),
    { enabled: isReady }
  );

  const users = handleUsers(usersResponse?.data?.data || []);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/users?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/users?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/users?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/users?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (usersResponse?.error) {
      showError(usersResponse?.error);
    }
  }, [usersResponse?.error]);

  return {
    page,
    total: usersResponse?.data?.meta?.total,
    loading: usersResponse?.isLoading,
    users,
    pageSize,
    getUsers: usersResponse.refetch,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
