import dynamic from 'next/dynamic';

const DriversePartnersGrid = dynamic(() =>
  import('./DriversePartnersGrid/DriversePartnersGrid')
);
const DriversePartnersFilters = dynamic(() =>
  import('./DriversePartnersFilters/DriversePartnersFilters')
);
const DriversePartnerForm = dynamic(() =>
  import('./DriversePartnerForm/DriversePartnerForm')
);

export { DriversePartnersGrid, DriversePartnersFilters, DriversePartnerForm };
