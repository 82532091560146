import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { LIMIT } from '@constants';
import { useQuery } from 'react-query';
import { loggerServices } from '@services';
import { QUERY_KEYS } from 'constants/queryKeys';
import { handleErrorData } from './utils';

export const useErrorOperations = () => {
  const {
    push,
    query: {
      page = 1,
      pageSize = LIMIT,
      orderBy = 'createdAt',
      order = 'desc',
      token,
    },
    isReady,
  } = useRouter();

  const _nextPage = useCallback(
    (currentPage) => {
      if (currentPage === 1) {
        return push('/errors');
      }
      push(`/errors?page=${currentPage}`, undefined, { shallow: true });
    },
    [push]
  );

  const errorsResponse = useQuery(
    [QUERY_KEYS.GET_LOGGER_KEY, page, order, pageSize, orderBy],
    () =>
      loggerServices?.getErrors({
        page,
        order,
        pageSize,
        orderBy,
      }),
    { enabled: isReady }
  );

  const errors = handleErrorData(errorsResponse?.data?.data || []);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorsResponse?.error) {
      showError(errorsResponse?.error);
    }
  }, [errorsResponse?.error]);

  return {
    page,
    total: errorsResponse?.data?.meta?.total,
    loading: errorsResponse?.isLoading,
    errors,
    pageSize,
    getErrors: errorsResponse.refetch,
    nextPage: _nextPage,
    token,
  };
};
