import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { asyncForEach, showError } from '@utils';
import { LIMIT } from '@constants';
import { getServicePointsRequestPayload } from '@helpers';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { servicePointsServices } from '@services';
import { handleServicePointsData } from './utils';

export const useServicePointsOperations = () => {
  const {
    push,
    query: {
      page = 1,
      pageSize = LIMIT,
      orderBy = 'internalId',
      order = 'asc',
      token,
      ...others
    },
    isReady,
  } = useRouter();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const servicePointsResponse = useQuery(
    [
      QUERY_KEYS.GET_SERVICE_POINTS_KEY,
      {
        page,
        orderBy,
        order,
        _otherQueries,
      },
    ],
    () => {
      const payload = getServicePointsRequestPayload({
        ...others,
      });
      return servicePointsServices?.getServicePointsFilters({
        page,
        orderBy,
        order,
        ...payload,
      });
    },
    { enabled: isReady }
  );

  const servicePoints = handleServicePointsData(
    servicePointsResponse?.data?.data || []
  );

  const _deleteServicePoints = useCallback(
    async (servicePointsIds) => {
      await asyncForEach(servicePointsIds, async (servicePointId) => {
        await servicePointsServices?.deleteServicePoint(servicePointId);
      });
      await servicePointsResponse.refetch();
    },
    [servicePointsResponse]
  );

  const servicePointsEnumsResponse = useQuery(
    [QUERY_KEYS.GET_SERVICE_POINTS_ENUMS_KEY],
    () => servicePointsServices?.getServicePointsEnums(),
    { enabled: isReady }
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/servicePoints?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/servicePoints?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/servicePoints?page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/servicePoints?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  const _activateDeactivateServicePoints = useCallback(
    async (payload) => {
      await servicePointsServices?.activateDeactivateServicePoints(payload);
      servicePointsResponse.refetch();
    },
    [servicePointsResponse]
  );

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (servicePointsResponse.error || servicePointsEnumsResponse.error) {
      showError(
        servicePointsResponse.error || servicePointsEnumsResponse.error
      );
    }
  }, [servicePointsResponse.error, servicePointsEnumsResponse.error]);

  return {
    page,
    pageSize,
    total: servicePointsResponse?.data?.meta?.total,
    loading: servicePointsResponse?.isLoading,
    servicePoints,
    enums: servicePointsEnumsResponse?.data?.data,
    getServicePoints: servicePointsResponse.refetch,
    nextPage: _nextPage,
    sortBy: _sortBy,
    deleteServicePoints: _deleteServicePoints,
    activateDeactivateServicePoints: _activateDeactivateServicePoints,
  };
};
