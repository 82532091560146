import dynamic from 'next/dynamic';

const CalendarIcon = dynamic(() => import('./CalendarIcon'));
const CallIcon = dynamic(() => import('./CallIcon'));
const CarIcon = dynamic(() => import('./CarIcon'));
const CellphoneIcon = dynamic(() => import('./CellphoneIcon'));
const CertifiedIcon = dynamic(() => import('./CertifiedIcon'));
const CopyIcon = dynamic(() => import('./CopyIcon'));
const GlobeIcon = dynamic(() => import('./GlobeIcon'));
const InfoIcon = dynamic(() => import('./InfoIcon'));
const PlusIcon = dynamic(() => import('./PlusIcon'));
const ProfileIcon = dynamic(() => import('./ProfileIcon'));
const ToolsIcon = dynamic(() => import('./ToolsIcon'));
const FuelIcon = dynamic(() => import('./FuelIcon'));
const UmbrellaIcon = dynamic(() => import('./UmbrellaIcon'));
const KeyIcon = dynamic(() => import('./KeyIcon'));
const BankIcon = dynamic(() => import('./BankIcon'));
const EuroIcon = dynamic(() => import('./EuroIcon'));
const ClockIcon = dynamic(() => import('./ClockIcon'));
const DeleteIcon = dynamic(() => import('./DeleteIcon'));
const StatusIcon = dynamic(() => import('./StatusIcon'));
const OrderingIcon = dynamic(() => import('./OrderingIcon'));
const FileIcon = dynamic(() => import('./FileIcon'));
const EllipsisVerticalIcon = dynamic(() => import('./EllipsisVerticalIcon'));
const ColumnIcon = dynamic(() => import('./ColumnIcon'));
const ListIcon = dynamic(() => import('./ListIcon'));
const EditSquareIcon = dynamic(() => import('./EditSquareIcon'));
const EmailIcon = dynamic(() => import('./EmailIcon'));
const UnssignedIcon = dynamic(() => import('./UnssignedIcon'));
const AssignedToMeIcon = dynamic(() => import('./AssignedToMeIcon'));
const RefreshIcon = dynamic(() => import('./RefreshIcon'));
const ClosedEmailIcon = dynamic(() => import('./ClosedEmailIcon'));
const OpenEmailIcon = dynamic(() => import('./OpenEmailIcon'));
const TeamIcon = dynamic(() => import('./TeamIcon'));
const StatusBlueIcon = dynamic(() => import('./StatusBlueIcon'));
const ProfileBlueIcon = dynamic(() => import('./ProfileBlueIcon'));
const ColumnUnsortedIcon = dynamic(() => import('./ColumnUnsortedIcon'));
const ColumnSortedAscendingIcon = dynamic(() =>
  import('./ColumnSortedAscendingIcon')
);
const ColumnSortedDescendingIcon = dynamic(() =>
  import('./ColumnSortedDescendingIcon')
);
const CancelIconStatus = dynamic(() => import('./CancelIconStatus'));
const PendingIconStatus = dynamic(() => import('./PendingIconStatus'));
const ApprovedIconStatus = dynamic(() => import('./ApprovedIconStatus'));
const InProgressIconStatus = dynamic(() => import('./InProgressIconStatus'));
const AttachmentIcon = dynamic(() => import('./AttachmentIcon'));

// Font Awesome Icons
const FACopyIcon = dynamic(() => import('./FontAwesome/FACopyIcon'));
const FAWarehouseIcon = dynamic(() => import('./FontAwesome/FAWarehouseIcon'));
const FABullseyeIcon = dynamic(() => import('./FontAwesome/FABullseyeIcon'));
const FACommentIcon = dynamic(() => import('./FontAwesome/FACommentIcon'));
const FAHistoryIcon = dynamic(() => import('./FontAwesome/FAHistoryIcon'));
const FAListCheckIcon = dynamic(() => import('./FontAwesome/FAListCheckIcon'));
const FACarIcon = dynamic(() => import('./FontAwesome/FACarIcon'));
const FAUserIcon = dynamic(() => import('./FontAwesome/FAUserIcon'));

export {
  CalendarIcon,
  CallIcon,
  CarIcon,
  CellphoneIcon,
  CertifiedIcon,
  CopyIcon,
  GlobeIcon,
  InfoIcon,
  PlusIcon,
  ProfileIcon,
  ToolsIcon,
  FuelIcon,
  UmbrellaIcon,
  KeyIcon,
  BankIcon,
  EuroIcon,
  ClockIcon,
  DeleteIcon,
  StatusIcon,
  OrderingIcon,
  FileIcon,
  EllipsisVerticalIcon,
  ListIcon,
  ColumnIcon,
  EditSquareIcon,
  EmailIcon,
  UnssignedIcon,
  AssignedToMeIcon,
  RefreshIcon,
  ClosedEmailIcon,
  OpenEmailIcon,
  TeamIcon,
  StatusBlueIcon,
  ProfileBlueIcon,
  ColumnUnsortedIcon,
  ColumnSortedAscendingIcon,
  ColumnSortedDescendingIcon,
  CancelIconStatus,
  PendingIconStatus,
  ApprovedIconStatus,
  InProgressIconStatus,
  AttachmentIcon,
  FACopyIcon,
  FAWarehouseIcon,
  FABullseyeIcon,
  FACommentIcon,
  FAHistoryIcon,
  FAListCheckIcon,
  FACarIcon,
  FAUserIcon,
};
