import dynamic from 'next/dynamic';

const DriverseCampaignsGrid = dynamic(() =>
  import('./DriverseCampaignsGrid/DriverseCampaignsGrid')
);
const DriverseCampaignsFilters = dynamic(() =>
  import('./DriverseCampaignsFilters/DriverseCampaignsFilters')
);
const DriverseCampaignForm = dynamic(() =>
  import('./DriverseCampaignForm/DriverseCampaignForm')
);
const DriverseCampaignCouponsSection = dynamic(() =>
  import('./DriverseCampaignCouponsSection/DriverseCampaignCouponsSection')
);

export {
  DriverseCampaignsGrid,
  DriverseCampaignsFilters,
  DriverseCampaignForm,
  DriverseCampaignCouponsSection,
};
