import { pickBy } from 'lodash';

const getCleanedUrlParams = (params) => {
  return pickBy(
    params,
    (item) => item !== null && item !== undefined && item !== ''
  );
};

export default getCleanedUrlParams;
