import { request } from '@utils';
import { FILTER_RULE_ENUMS, LIMIT } from '@constants';

const getVehicles = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }
  const results = await request.get(`/v1/instafleet/catalog/vehicles`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehiclesFilters = async ({ params, payload }) => {
  if (!params?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!params.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }

  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/catalog/vehicles/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehiclesEnums = async () => {
  const results = await request.get(`/v1/catalog/vehicles/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const importCatalog = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/catalog/vehicles/import`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicle = async (id) => {
  const results = await request.get(`/v1/instafleet/catalog/vehicles/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleEnums = async () => {
  const results = await request.get(`/v1/catalog/vehicles/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehiclesBySku = async (id, params = {}) => {
  const results = await request.get(
    `/v1/vehicles/sku/${id}?${new URLSearchParams(params)}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehicle = async (params) => {
  const payload = {
    body: params,
  };
  const results = await request.post(`/v1/catalog/vehicles`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editVehicleImage = async (
  skuId,
  imageId,
  imageTag,
  caption,
  description,
  order
) => {
  const body = {
    body: {
      tags: imageTag,
      caption,
      description,
      order,
    },
  };
  const results = await request.patch(
    `/v1/catalog/vehicles/${skuId}/images/${imageId}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehicleImage = async (
  skuId,
  file,
  imageTag,
  caption,
  description,
  order
) => {
  const fd = new FormData();
  fd.append('images', file);
  fd.append('tags', imageTag);
  fd.append('caption', caption);
  fd.append('description', description);
  fd.append('order', order);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/images`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehicleFile = async (skuId, file) => {
  const fd = new FormData();
  fd.append('specs_sheet', file);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/docs`,
    body
  );
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const updateVehicle = async (skuId, body) => {
  const params = {
    body,
  };
  const results = await request.patch(`/v1/catalog/vehicles/${skuId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteVehicleImage = async (payload) => {
  const results = await request.remove(
    `/v1/catalog/vehicles/${payload?.skuId}/images/${payload?.id}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteVehicleFile = async (payload) => {
  const results = await request.remove(
    `/v1/catalog/vehicles/${payload?.skuId}/docs/${payload?.id}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportFile = async ({ type, page = 1, pageSize = 40 }) => {
  let payload = localStorage.marketVehiclesQuery;
  let { dropdownFilterRules } = localStorage;
  if (dropdownFilterRules) {
    dropdownFilterRules = JSON.parse(dropdownFilterRules);
  } else {
    dropdownFilterRules = [];
  }
  if (payload) {
    payload = JSON.parse(payload);
    if (payload.value) {
      payload.value = [...payload.value, ...dropdownFilterRules];
    } else if (dropdownFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: dropdownFilterRules,
      };
    }
  } else if (!payload && dropdownFilterRules?.length) {
    payload = {
      type: 0,
      field: null,
      operator: null,
      value: dropdownFilterRules,
    };
  } else {
    payload = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
  }
  const body = {
    body: payload,
    responseType: 'text',
  };

  const results = await request.post(
    `/v1/catalog/vehicles/export?type=${type}&page=${page}&pageSize=${pageSize}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addPricing = async (skuId, payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/pricing`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updatePricing = async (skuId, pricingId, body) => {
  const params = {
    body,
  };
  const results = await request.patch(
    `/v1/catalog/vehicles/${skuId}/pricing/${pricingId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehiclePrimaryImage = async (skuId, imageId) => {
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/images/${imageId}/select`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getVehicles,
  getVehiclesBySku,
  getVehicle,
  importCatalog,
  getVehicleEnums,
  postVehicle,
  postVehicleImage,
  editVehicleImage,
  updateVehicle,
  deleteVehicleImage,
  postVehicleFile,
  deleteVehicleFile,
  exportFile,
  addPricing,
  updatePricing,
  getVehiclesEnums,
  getVehiclesFilters,
  postVehiclePrimaryImage,
};
