import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ordersServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { handleOrder, handleTransactions } from './orders.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  orders: [],
  order: {},
  transactions: {},
  total: 0,
  pageCount: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageSize: LIMIT,
};
// Actual Slice
export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    fetchOrder: (state) => {
      state.loading = true;
      state.getOrderhasErrors = false;
      state.getOrderErrorMessage = '';
    },
    fetchOrderSuccess: (state, { payload }) => {
      state.order = handleOrder(payload?.order?.data);
      state.transactions = handleTransactions(payload?.transactions?.data);
      state.loading = false;
    },
    fetchOrderFailure: (state, { payload }) => {
      state.loading = false;
      state.getOrderhasErrors = true;
      state.getOrderErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchOrder, fetchOrderSuccess, fetchOrderFailure } =
  ordersSlice.actions;

export const selectOrdersState = (state) => state.orders;

export const getOrder = (orderId) => {
  return async (dispatch) => {
    dispatch(fetchOrder());

    try {
      const order = await ordersServices?.getOrdeById(orderId);
      const transactions = await ordersServices?.getTransactionsByOrderId(
        orderId
      );

      dispatch(fetchOrderSuccess({ order, transactions }));
    } catch (error) {
      dispatch(fetchOrderFailure(error));
    }
  };
};

export default ordersSlice.reducer;
