import { request } from '@utils';
import { LIMIT } from '@constants';

const getAgents = async (payload = {}) => {
  if (!payload?.page) {
    payload.page = 1;
  }
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  // if (!payload?.isActive) {
  //   payload.isActive = true;
  // }
  const results = await request.get(`/v1/agents`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateAgentRole = async (id, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/agents/${id}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const createAgent = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/agents`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const removeAgent = async (id) => {
  const results = await request.remove(`/v1/agents/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editAgent = async (id, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/agents/${id}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getFilterAgents = async ({ params, payload }) => {
  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/agents/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getAgents,
  updateAgentRole,
  createAgent,
  removeAgent,
  editAgent,
  getFilterAgents,
};
