export const URLS = {
  home: '/',
  dailyBookings: '/dailyBookings',
  communication: '/communication',
  bookings: '/bookings',
  availability: '/availabilities-table',
  reservations: '/reservations',
  campaigns: '/campaigns',
  fleetVehicles: '/vehicles',
  logistics: '#0',
  sellVehicles: '/sellVehicles',
  buyout: '/buyout',
  sellRequests: '/sellRequests',
  returns: '/returnsInbox',
  customerSuccessDashboard: './customer-success-dashboard',
  subscriptions: '/subscriptions',
  trafficOffenses: '/trafficOffenses',
  arnmDashboard: '/dashboard',
  arnmRepairs: '/arnmRepairs',
  arnmMaintenance: '/arnmMaintenance',
  arnmAccidents: '/arnmAccidents',
  billing: '/billing',
  serviceVehicles: '/serviceVehicles',
  insurances: '/insurances',
  roadsideAssistance: '/roadsideAssistance',
  servicePoints: '/servicePoints',
  catalogCars: '/catalog/cars',
  catalogEbikes: '/catalog/ebike',
  vendors: '/vendors',
  orders: '/orders',
  users: '/users',
  handoverCarsDelivery: '/handoverCarsDelivery',
  handoverCarsReturns: '/handoverCarsReturns',
  inspections: '/inspections',
  creditCheckReport: '/credit_check_report',
  instapaymentReports: '/instapayment_reports',
  avisReport: '/avis_report',
  utilities: '/utilities',
  errors: '/errors',
  agents: '/agents',
  teams: '/teams',
  configurations: '/configurations',
  permissions: '/permissions',
  partners: '/partners',
  greenCard: '/greenCard',
  driverseCampaigns: '/driverseCampaigns',
  driversePartners: '/driversePartners',
  defleet: '/defleet',
};
