export const handleDriversePartners = (data = []) => {
  if (!data || !data.length) {
    return [];
  }

  return data.map((item) => ({
    ...item,
  }));
};

export const handleDriverseMembers = (data = []) => {
  if (!data || !data.length) {
    return [];
  }

  return data
    ?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    ?.map((item) => ({
      text: item?.name,
      value: item?.id,
    }));
};

export const handleDriversePartner = (data = {}) => {
  if (!data) {
    return {};
  }

  return {
    id: data?.id,
    name: data?.name,
    logo: data?.logo,
    description: data?.description,
    isActive: data?.isActive,
  };
};
