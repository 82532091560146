import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { servicePointsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import {
  handleServicePointData,
  handleServicePointsEventsData,
} from './servicePoints.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  servicePoint: {},
  events: [],
  total: 0,
  pageSize: LIMIT,
  enums: {},
};
// Actual Slice
export const servicePointsSlice = createSlice({
  name: 'servicePoints',
  initialState,
  reducers: {
    fetchServicePoint: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.eventsLoading = false;
      state.events = [];
      state.errorMessage = '';
      state.servicePoint = {};
    },
    fetchServicePointSuccess: (state, { payload }) => {
      state.servicePoint = handleServicePointData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServicePointFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.eventsLoading = false;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchServicePointsEvents: (state) => {
      state.eventsLoading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServicePointsSuccessEvents: (state, { payload }) => {
      state.eventsLoading = false;
      state.events = handleServicePointsEventsData(payload?.data);
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchServicePoint,
  fetchServicePointSuccess,
  fetchServicePointFailure,
  fetchServicePointsEvents,
  fetchServicePointsSuccessEvents,
} = servicePointsSlice.actions;

export const selectServicePointsState = (state) => state.servicePoints;

export const getServicePoint = (id) => {
  return async (dispatch) => {
    dispatch(fetchServicePoint());

    try {
      const servicePoint = {};
      const details = await servicePointsServices?.getServicePoint(id);
      servicePoint.details = details?.data;
      const addresses = await servicePointsServices?.getServicePointAddresses(
        id
      );
      servicePoint.addresses = addresses?.data;

      dispatch(fetchServicePointSuccess(servicePoint));
    } catch (error) {
      dispatch(fetchServicePointFailure(error));
    }
  };
};

export const getServicePointEvents = (id) => {
  return async (dispatch) => {
    dispatch(fetchServicePointsEvents());

    try {
      const events = await servicePointsServices?.syncServicePointEvents(id);

      dispatch(fetchServicePointsSuccessEvents(events));
    } catch (error) {
      dispatch(fetchServicePointFailure(error));
    }
  };
};

export default servicePointsSlice.reducer;
