import { request } from '@utils';

const instapaymentReport = async (type) => {
  const params = {
    type,
    responseType: 'arraybuffer',
  };
  const results = await request.get(`/v1/reports/instapayment`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const avisReport = async (months) => {
  const params = {
    months,
    responseType: 'arraybuffer',
  };
  const results = await request.get(`/v1/reports/avis`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const arnmSLAReport = async () => {
  const params = {
    responseType: 'arraybuffer',
    page: 1,
    pageSize: 10000,
  };
  const results = await request.get(`/v1/reports/arm`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { instapaymentReport, avisReport, arnmSLAReport };
