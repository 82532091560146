import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { roadsideAssistanceServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { handleRoadsideAssistanceData } from './roadsideAssistance.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  roadsideAssistances: [],
  roadsideAssistance: {},
  total: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const roadsideAssistanceSlice = createSlice({
  name: 'roadsideAssistance',
  initialState,
  reducers: {
    fetchRoadsideAssistance: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchRoadsideAssistanceSuccess: (state, { payload }) => {
      state.roadsideAssistance = handleRoadsideAssistanceData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchRoadsideAssistanceFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchRoadsideAssistance,
  fetchRoadsideAssistanceSuccess,
  fetchRoadsideAssistanceFailure,
} = roadsideAssistanceSlice.actions;

export const selectRoadsideAssistancesState = (state) =>
  state.roadsideAssistance;

export const getRoadsideAssistance = (id) => {
  return async (dispatch) => {
    dispatch(fetchRoadsideAssistance());

    try {
      const details = await roadsideAssistanceServices?.getRoadsideAssistance(
        id
      );

      dispatch(fetchRoadsideAssistanceSuccess(details?.data));
    } catch (error) {
      dispatch(fetchRoadsideAssistanceFailure(error));
    }
  };
};

export default roadsideAssistanceSlice.reducer;
