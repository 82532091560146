import { pick, omit } from 'lodash';
import { FILTER_RULE_ENUMS, FILTER_OPERATOR_ENUMS, LIMIT } from '@constants';

export const getServicePointsQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  return pick(payload, ['page', 'pageSize', 'order', 'orderBy']);
};

const BOOLEAN_VALUES = {
  certified: true,
  notCertified: false,
};

const FIELDS_MAP = {
  status: 'partnershipInfo.isCertified',
  workType: 'supportedWorkTypes',
  manufacturer: 'supportedManufacturers',
  insurance: 'supportedInsurances',
};

export const getServicePointsRequestPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields = omit(payload, ['page', 'pageSize', 'order', 'orderBy']) || {};
  const numberFields = ['workType', 'manufacturer'];
  const booleanFields = ['status'];
  const multyFilterStringFields = [];
  const multyFilterNumberFields = [];

  const _getValue = ({ field }) => {
    if (booleanFields.includes(field)) {
      return BOOLEAN_VALUES[payload[field]];
    }
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return numberFields.includes(field)
      ? Number(payload[field])
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field: FIELDS_MAP[field],
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  value.push(...(modalFiltersRules?.value || []));

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
