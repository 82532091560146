import { request } from '@utils';

const getDriverseMembers = async ({ params, payload }) => {
  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/driverse/members/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getDriverseMemberById = async (memberId) => {
  const results = await request.get(`/v1/driverse/members/${memberId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateDriverseMember = async (memberId, payload) => {
  const body = {
    body: payload,
  };

  const results = await request.patch(`/v1/driverse/members/${memberId}`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postDriverseMember = async (payload) => {
  const body = {
    body: payload,
  };

  const results = await request.post(`/v1/driverse/members`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getDriverseCampaigns = async ({ params, payload }) => {
  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/driverse/campaigns/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getDriverseCampaignById = async (campaignId) => {
  const results = await request.get(`/v1/driverse/campaigns/${campaignId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postDriverseCampaigns = async (payload) => {
  const body = {
    body: payload,
  };

  const results = await request.post(`/v1/driverse/campaigns`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateDriverseCampaigns = async (campainId, payload) => {
  const body = {
    body: payload,
  };

  const results = await request.patch(
    `/v1/driverse/campaigns/${campainId}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getDriverseCoupons = async ({ params, payload }) => {
  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/driverse/coupons/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addDriverseCoupon = async (payload) => {
  const body = {
    body: payload,
  };

  const results = await request.post(`/v1/driverse/coupons`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const generateDriverseCoupon = async (payload) => {
  const body = {
    body: payload,
  };

  const results = await request.post(`/v1/driverse/coupons/generate`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getDriverseMembers,
  getDriverseMemberById,
  updateDriverseMember,
  postDriverseMember,
  getDriverseCampaigns,
  postDriverseCampaigns,
  updateDriverseCampaigns,
  getDriverseCoupons,
  addDriverseCoupon,
  generateDriverseCoupon,
  getDriverseCampaignById,
};
