import dynamic from 'next/dynamic';

const BuyOutGrid = dynamic(() => import('./BuyOutGrid/BuyOutGrid'));
const AddBuyOutModal = dynamic(() => import('./AddBuyOutModal/AddBuyOutModal'));
const UploadBuyOutDocument = dynamic(() =>
  import('./UploadBuyOutDocument/UploadBuyOutDocument')
);
const BuyOutVehicleHeader = dynamic(() =>
  import('./BuyOutVehicleHeader/BuyOutVehicleHeader')
);
const GridCellApprovalStatus = dynamic(() =>
  import('./GridCellApprovalStatus/GridCellApprovalStatus')
);
const ChangeBuyOutStage = dynamic(() =>
  import('./ChangeBuyOutStage/ChangeBuyOutStage')
);
const BuyOutFilters = dynamic(() => import('./BuyOutFilters/BuyOutFilters'));

export {
  BuyOutGrid,
  AddBuyOutModal,
  UploadBuyOutDocument,
  BuyOutVehicleHeader,
  GridCellApprovalStatus,
  ChangeBuyOutStage,
  BuyOutFilters,
};

/* PLOP_INJECT_COMPONENT_EXPORT */
