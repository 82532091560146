import { request } from '@utils';

const postSearch = async (query) => {
  const baseURL =
    process.env.FEATURE_ENABLE_GLOBAL_SEARCH_V2 === 'true' ? '/v2' : '/v1';

  const results = await request.post(`${baseURL}/search?query=${query}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { postSearch };
