import {
  computeModelData,
  fixedNumber,
  formatAmount,
  formatDate,
} from '@utils';
import { BOARDS_ID } from '@constants';
import {
  getAvailability,
  getBodySize,
  getBodyType,
  getCategory,
  getDealer,
  getEngineType,
  getEuroClass,
  getFuelType,
  getProductLines,
  getTransmission,
} from './sellVehicles.helpers';
import { INITIAL_VALUES } from './constants';

export const handleSellVehicles = (array) => {
  if (!array) {
    return [];
  }

  array?.forEach((item, index, newArray) => {
    const row = {
      availability: item.availability,
      brand: item?.sku?.brand || '-',
      category: item?.sku?.category,
      edition: item?.sku?.edition?.trim() || '-',
      year: item?.sku?.year || '-',
      engineDisplacement: item?.sku?.engine?.displacement,
      fuelType: item?.sku?.engine?.fuelType,
      engineType: item?.sku?.engine?.type,
      image: item?.image?.url || item?.images?.[0]?.url || '',
      initialPrice: item.initialPrice
        ? formatAmount(fixedNumber(item.initialPrice))
        : '-',
      mileageFromOdometer: item.mileageFromOdometer || '-',
      model: item?.sku?.model || '-',
      name:
        item.name ||
        `${item?.sku?.brand} ${item?.sku?.model} ${item?.sku?.edition}` ||
        '-',
      price: item.price ? formatAmount(fixedNumber(item.price)) : '-',
      status: item.published ? 'Published' : 'Unpublished',
      published: item.published,
      skuId: item.skuId || '-',
      vehicleInternalId: item.vehicleInternalId || '-',
      internalId: item.internalId || '-',
      vin: item.vin || '-',
      plate: item.plate || '-',
      transmission: item?.sku?.transmission,
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      id: item.id,
      bodyType: item?.sku?.body?.type,
      powerHp: item?.sku?.engine?.powerHp || '-',
      batterySize: item?.sku?.engine?.batterySize || '-',
      euroClass: item?.sku?.engine?.euroClass,
    };
    newArray[index] = row;

    return newArray;
  });
  return array;
};

export const handleSellVehicleData = (data) => {
  if (
    !('enums' in data) &&
    !('colors' in data) &&
    !('providers' in data) &&
    !('vehicle' in data) &&
    !('models' in data)
  ) {
    return {};
  }
  if (data?.enums?.data?.interiorTypes) {
    data.enums.data.interiorTypes = Object.keys(
      data?.enums?.data?.interiorTypes
    )?.map((type) => {
      return data?.enums?.data?.interiorTypes[type];
    });
  }
  data.enums = data.enums.data;
  data.colors = data?.colors?.data;
  data.providers = data?.providers?.data;
  data.models = data.models.data;
  data.vehicle = data?.vehicle?.data;

  if (data.vehicle) {
    // normalize sku values
    data.vehicle.skuId = data?.vehicle?.skuId?.slice(2);
    data.vehicleOwnership = getDealer(
      data?.enums,
      data?.vehicle?.vehicleOwnership
    );
    data.vehicle.sku.engine.fuelType = getFuelType(
      data?.enums,
      data?.vehicle?.sku?.engine?.fuelType
    );
    data.vehicle.sku.category = getCategory(
      data?.enums,
      data?.vehicle?.sku?.category
    );
    data.vehicle.sku.engine.euroClass = getEuroClass(
      data?.enums,
      data?.vehicle?.sku?.engine?.euroClass
    );
    data.vehicle.availability = getAvailability(
      data?.enums,
      data?.vehicle?.availability,
      'id'
    );
    data.vehicle.sku.engine.type = getEngineType(
      data?.enums,
      data?.vehicle?.sku?.engine?.type
    );
    data.vehicle.sku.transmission = getTransmission(
      data?.enums,
      data?.vehicle?.sku?.transmission
    );
    data.vehicle.sku.body.type = getBodyType(
      data?.enums,
      data?.vehicle?.sku?.body?.type
    );
    data.vehicle.sku.body.size = getBodySize(
      data?.enums,
      data?.vehicle?.sku?.body?.size
    );
    data.vehicle.sku.productLine = getProductLines(
      data?.enums,
      data?.vehicle?.sku?.productLine
    );
    if (data?.vehicle?.sku?.equipment?.comfort) {
      data.vehicle.sku.equipment.comfort['el-GR'] =
        data?.vehicle?.sku?.equipment?.comfort?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.comfort['en-US'] =
        data?.vehicle?.sku?.equipment?.comfort?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.sku?.equipment?.exterior) {
      data.vehicle.sku.equipment.exterior['el-GR'] =
        data?.vehicle?.sku?.equipment?.exterior?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.exterior['en-US'] =
        data?.vehicle?.sku?.equipment?.exterior?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.sku?.equipment?.interior) {
      data.vehicle.sku.equipment.interior['el-GR'] =
        data?.vehicle?.sku?.equipment?.interior?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.interior['en-US'] =
        data?.vehicle?.sku?.equipment?.interior?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.sku?.equipment?.multimedia) {
      data.vehicle.sku.equipment.multimedia['el-GR'] =
        data?.vehicle?.sku?.equipment?.multimedia?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.multimedia['en-US'] =
        data?.vehicle?.sku?.equipment?.multimedia?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.sku?.equipment?.safety) {
      data.vehicle.sku.equipment.safety['el-GR'] =
        data?.vehicle?.sku?.equipment?.safety?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.safety['en-US'] =
        data?.vehicle?.sku?.equipment?.safety?.['en-US']?.join('<br>');
    }
  } else {
    data.vehicle = INITIAL_VALUES;
  }

  data = { ...data, ...computeModelData(data?.models) };

  return data;
};

export const handleLeaseVehicleData = (data) => {
  if (
    !('enums' in data) &&
    !('colors' in data) &&
    !('providers' in data) &&
    !('vehicle' in data) &&
    !('models' in data)
  ) {
    return {};
  }
  if (data?.enums?.data?.interiorTypes) {
    data.enums.data.interiorTypes = Object.keys(
      data?.enums?.data?.interiorTypes
    )?.map((type) => {
      return data?.enums?.data?.interiorTypes[type];
    });
  }
  data.enums = data.enums.data;
  data.colors = data?.colors?.data;
  data.providers = data?.providers?.data;
  data.models = data.models.data;
  data.vehicle = data?.vehicle?.data;
  data.vehicle.sku = { ...data?.vehicle?.properties };

  if (data.vehicle) {
    // normalize sku values
    data.vehicle.skuId = data?.vehicle?.skuId?.slice(2);
    data.vehicle.sku.engine.fuelType = getFuelType(
      data?.enums,
      data?.vehicle?.properties?.engine?.fuelType
    );
    data.vehicle.sku.category = getCategory(
      data?.enums,
      data?.vehicle?.properties?.category
    );
    data.vehicle.sku.engine.euroClass = getEuroClass(
      data?.enums,
      data?.vehicle?.properties?.engine?.euroClass
    );
    data.vehicle.availability = getAvailability(
      data?.enums,
      data?.vehicle?.availability
    );
    data.vehicle.sku.engine.type = getEngineType(
      data?.enums,
      data?.vehicle?.properties?.engine?.type
    );
    data.vehicle.sku.transmission = getTransmission(
      data?.enums,
      data?.vehicle?.properties?.transmission
    );
    data.vehicle.sku.body.type = getBodyType(
      data?.enums,
      data?.vehicle?.properties?.body?.type
    );
    data.vehicle.sku.body.size = getBodySize(
      data?.enums,
      data?.vehicle?.properties?.body?.size
    );
    data.vehicle.sku.productLine = getProductLines(
      data?.enums,
      data?.vehicle?.properties?.productLine
    );
    if (data?.vehicle?.properties?.equipment?.comfort) {
      data.vehicle.sku.equipment.comfort['el-GR'] =
        data?.vehicle?.properties?.equipment?.comfort?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.comfort['en-US'] =
        data?.vehicle?.properties?.equipment?.comfort?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.properties?.equipment?.exterior) {
      data.vehicle.sku.equipment.exterior['el-GR'] =
        data?.vehicle?.properties?.equipment?.exterior?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.exterior['en-US'] =
        data?.vehicle?.properties?.equipment?.exterior?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.properties?.equipment?.interior) {
      data.vehicle.sku.equipment.interior['el-GR'] =
        data?.vehicle?.properties?.equipment?.interior?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.interior['en-US'] =
        data?.vehicle?.properties?.equipment?.interior?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.properties?.equipment?.multimedia) {
      data.vehicle.sku.equipment.multimedia['el-GR'] =
        data?.vehicle?.properties?.equipment?.multimedia?.['el-GR']?.join(
          '<br>'
        );
      data.vehicle.sku.equipment.multimedia['en-US'] =
        data?.vehicle?.properties?.equipment?.multimedia?.['en-US']?.join(
          '<br>'
        );
    }
    if (data?.vehicle?.properties?.equipment?.safety) {
      data.vehicle.sku.equipment.safety['el-GR'] =
        data?.vehicle?.properties?.equipment?.safety?.['el-GR']?.join('<br>');
      data.vehicle.sku.equipment.safety['en-US'] =
        data?.vehicle?.properties?.equipment?.safety?.['en-US']?.join('<br>');
    }
  } else {
    data.vehicle = INITIAL_VALUES;
  }

  data = { ...data, ...computeModelData(data?.models) };

  return data;
};

export const addHistory = (vehicle, history) => {
  const mapTypes = {
    [BOARDS_ID.ARM_ACCIDENTS]: { title: 'New Accident', key: 'arnmAccidents' },
    [BOARDS_ID.ARM_REPAIRS]: { title: 'New Repair', key: 'arnmRepairs' },
    [BOARDS_ID.ARM_MAINTENANCE]: {
      title: 'New Maintenance',
      key: 'arnmMaintenance',
    },
  };

  if (!vehicle) {
    return null;
  }
  vehicle.history = history.map((item) => ({
    ...item,
    field: [mapTypes[item.type]?.title],
    task: {
      id: item.id,
      link: `/${mapTypes[item.type]?.key}/${item.id}`,
    },
  }));

  return vehicle;
};
