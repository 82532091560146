import { request } from '@utils';

const getSubscriptions = async ({ params, payload }) => {
  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/subscriptions/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSubscriptionsEnums = async () => {
  const results = await request.get(`/v1/subscriptions/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSubscriptionById = async (id) => {
  const results = await request.get(`/v1/subscriptions/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editSubscriptionById = async (subscriptionID, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionID}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editSubscriptionRuleById = async (subscriptionID, field, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionID}/rules/${field}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editSubscriberRole = async (subscriptionID, subscriberId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionID}/subscribers/${subscriberId}/role`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addFee = async (subscriptionID, ruleId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionID}/rules/${ruleId}/fees`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addDriver = async (subscriptionID, body) => {
  const params = {
    body,
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionID}/subscribers`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const requestDrivers = async (subscriptionID) => {
  const params = {
    body: null,
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionID}/add-driver-mail`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const removeDriver = async (subscriptionId, userId) => {
  const results = await request.remove(
    `/v1/users/${userId}/subscription/${subscriptionId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const cancelHandover = async (subscriptionID, type) => {
  const params = {
    body: null,
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionID}/handover/${type}/cancel`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const cancelInspection = async (subscriptionID, type) => {
  const params = {
    body: null,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionID}/inspection/${type}/cancel`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSubscriptionHistoryById = async (id) => {
  const results = await request.get(
    `/v1/changelogs?entityType=subscription&entityID=${id}&orderBy=createdAt&order=desc`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addDocument = async (id, formData) => {
  const payload = {
    body: formData,
    header: {
      Accept: '*/*',
    },
  };

  const results = await request.post(`/v1/subscriptions/${id}/docs`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportSubscriptions = async ({ params, payload }) => {
  const body = {
    body: payload,
    responseType: 'text',
  };
  const results = await request.post(
    `/v1/subscriptions/export?${new URLSearchParams({
      ...params,
      type: 'csv',
    })}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editSubscriptionFee = async (subscriptionId, feeId, values) => {
  const params = {
    body: values,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionId}/fees/${feeId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteSubscriptionFee = async (subscriptionId, feeId) => {
  const results = await request.remove(
    `/v1/subscriptions/${subscriptionId}/fees/${feeId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const generateHandoverPdf = async (subscriptionId, type) => {
  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/pdf/generation`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSubscriptionBillingInfoByTin = async ({ subscriptionId, tin }) => {
  const results = await request.get(
    `/v1/subscriptions/${subscriptionId}/billingInfo/${tin}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getSubscriptions,
  getSubscriptionById,
  editSubscriptionRuleById,
  editSubscriptionById,
  editSubscriberRole,
  addFee,
  addDriver,
  getSubscriptionsEnums,
  requestDrivers,
  cancelHandover,
  getSubscriptionHistoryById,
  addDocument,
  exportSubscriptions,
  removeDriver,
  editSubscriptionFee,
  deleteSubscriptionFee,
  generateHandoverPdf,
  cancelInspection,
  getSubscriptionBillingInfoByTin,
};
