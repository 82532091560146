import { formatDate } from '@utils';

export const handleRoadsideAssistancesData = (array) => {
  if (!array) {
    return [];
  }
  const data = array?.map((item) => {
    return {
      email: item?.email || '-',
      internalId: item?.internalId || '-',
      name: item?.name || '-',
      slug: item?.slug || '-',
      telephoneNumber: item?.telephoneNumber || '-',
      id: item?.id,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
    };
  });
  return data;
};

export const handleRoadsideAssistanceData = (item) => {
  if (!item) {
    return {};
  }
  const roadsideAssistance = {
    email: {
      value: item?.email || '-',
    },
    internalId: {
      value: item?.internalId || '-',
      name: 'internalId',
    },
    name: {
      value: item?.name || '-',
      name: 'name',
    },
    slug: {
      value: item?.slug || '-',
    },
    phoneNumber: {
      value: item?.telephoneNumber || '-',
      name: 'phoneNumber',
    },
    id: item?.id,
    createdAt: {
      value: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
    },
    updatedAt: {
      value: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
    },
  };

  return roadsideAssistance;
};
